import { createI18n } from 'vue-i18n';

// Create i18n instance with empty messages initially
const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {}
});

// Dynamic language loading
export async function loadLanguage(locale) {
    // If the language is already loaded
    if (i18n.global.availableLocales.includes(locale)) {
        i18n.global.locale.value = locale;
        return;
    }

    // Dynamically import the language file
    try {
        const messages = await import(`./lang/${locale}.js`);
        i18n.global.setLocaleMessage(locale, messages.default);
        i18n.global.locale.value = locale;
    } catch (error) {
        console.error(`Could not load language ${locale}:`, error);
        // If English is not loaded yet and we failed to load requested language
        if (!i18n.global.availableLocales.includes('en')) {
            const enMessages = await import('./lang/en.js');
            i18n.global.setLocaleMessage('en', enMessages.default);
        }
        // Fallback to English
        i18n.global.locale.value = 'en';
    }
}

// Load English by default
loadLanguage('en');

export default i18n; 