<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';

// Development flag - set to true to always show popup in local environment
const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const POPUP_INTERVAL = isDevelopment ? 300000 : 6000000; // 30 seconds in dev, 10 minutes in prod

const showPopup = ref(false);
const selectedRole = ref(null);
const form = useForm({
    email: '',
    phone: '',
    role: '',
    country: ''
});

const emit = defineEmits(['close']);

const closePopup = (userClosed = true) => {
    if (userClosed && !isDevelopment) {
        // If user explicitly closed and not in development, store for 24 hours
        const expiryTime = new Date();
        expiryTime.setHours(expiryTime.getHours() + 24);
        localStorage.setItem('welcomePopupExpiry', expiryTime.toString());
    } else if (isDevelopment) {
        // In development, only hide for 30 seconds when closed
        const expiryTime = new Date();
        expiryTime.setSeconds(expiryTime.getSeconds() + 30);
        localStorage.setItem('welcomePopupExpiry', expiryTime.toString());
    }
    showPopup.value = false;
    emit('close');
};

const checkPopupStatus = () => {
    const expiry = localStorage.getItem('welcomePopupExpiry');
    if (!expiry || new Date(expiry) < new Date()) {
        showPopup.value = true;
        // In development, always clear the expiry when showing popup
        if (isDevelopment) {
            localStorage.removeItem('welcomePopupExpiry');
        }
    }
};

let popupInterval;

onMounted(() => {
    // Initial check after 1.5 seconds
    setTimeout(checkPopupStatus, 1500);

    // Set up interval to check periodically
    popupInterval = setInterval(() => {
        if (!showPopup.value) {
            checkPopupStatus();
        }
    }, POPUP_INTERVAL);
});

onUnmounted(() => {
    if (popupInterval) {
        clearInterval(popupInterval);
    }
});

const handleSubmit = () => {
    if (!selectedRole.value) return;
    closePopup(true);
    window.location.href = route('booking', selectedRole.value);
};
</script>

<template>
    <Transition
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
        <div v-if="showPopup" class="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <!-- Backdrop -->
            <div class="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm transition-opacity"></div>

            <!-- Main Container -->
            <div class="flex min-h-screen items-center justify-center p-3 sm:p-4">
                <div class="relative w-full max-w-lg sm:max-w-2xl transform overflow-hidden rounded-xl sm:rounded-2xl bg-white shadow-2xl transition-all">
                    <!-- Decorative header -->
                    <div class="absolute top-0 h-1 w-full bg-gradient-to-r from-primary via-secondary to-primary"></div>

                    <!-- Close button -->
                    <button 
                        @click="closePopup(true)" 
                        class="absolute right-2 sm:right-4 top-2 sm:top-4 text-gray-400 hover:text-gray-500 transition-colors"
                        aria-label="Close popup"
                    >
                        <span class="sr-only">Close</span>
                        <i class="fas fa-times text-lg sm:text-xl" aria-hidden="true"></i>
                    </button>

                    <!-- Content -->
                    <div class="p-6 sm:p-8">
                        <div class="text-center">
                            <h2 class="text-2xl sm:text-3xl font-bold text-gray-900 mb-2 sm:mb-3">
                                {{ t('welcome.title') }}
                            </h2>
                            <p class="text-gray-600 text-sm sm:text-base">
                                {{ t('welcome.description') }}
                            </p>
                        </div>

                        <!-- Role Selection -->
                        <div class="mt-6 sm:mt-8">
                            <h3 class="text-lg sm:text-xl font-semibold text-gray-900 mb-4 sm:mb-6">
                                {{ t('welcome.roleSelection') }}
                            </h3>
                            <div class="grid grid-cols-2 gap-4">
                                <div
                                    @click="selectedRole = 'traveler'"
                                    :class="[
                                        'p-3 sm:p-6 rounded-xl border-2 cursor-pointer transition-all duration-300 transform hover:scale-105',
                                        selectedRole === 'traveler'
                                            ? 'border-[#034328] bg-[#034328]/5 shadow-lg'
                                            : 'border-gray-200 hover:border-[#034328]/50'
                                    ]"
                                    role="button"
                                    tabindex="0"
                                    aria-label="Select traveler role"
                                    :aria-pressed="selectedRole === 'traveler'"
                                >
                                    <div class="text-center">
                                        <div class="w-12 h-12 sm:w-16 sm:h-16 bg-[#034328]/10 rounded-full flex items-center justify-center mx-auto mb-2 sm:mb-4">
                                            <i class="fas fa-plane text-xl sm:text-2xl text-[#034328]" aria-hidden="true"></i>
                                        </div>
                                        <h4 class="text-lg sm:text-xl font-semibold text-gray-900 mb-1 sm:mb-2">Traveler</h4>
                                        <p class="text-gray-600 text-xs sm:text-sm">$10 joining bonus</p>
                                    </div>
                                </div>

                                <div
                                    @click="selectedRole = 'carer'"
                                    :class="[
                                        'p-3 sm:p-6 rounded-xl border-2 cursor-pointer transition-all duration-300 transform hover:scale-105',
                                        selectedRole === 'carer'
                                            ? 'border-[#034328] bg-[#034328]/5 shadow-lg'
                                            : 'border-gray-200 hover:border-[#034328]/50'
                                    ]"
                                    role="button"
                                    tabindex="0"
                                    aria-label="Select carer role"
                                    :aria-pressed="selectedRole === 'carer'"
                                >
                                    <div class="text-center">
                                        <div class="w-12 h-12 sm:w-16 sm:h-16 bg-[#034328]/10 rounded-full flex items-center justify-center mx-auto mb-2 sm:mb-4">
                                            <i class="fas fa-hands-helping text-xl sm:text-2xl text-[#034328]" aria-hidden="true"></i>
                                        </div>
                                        <h4 class="text-lg sm:text-xl font-semibold text-gray-900 mb-1 sm:mb-2">Carer</h4>
                                        <p class="text-gray-600 text-xs sm:text-sm">$20 joining bonus</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Action Buttons -->
                    <div class="bg-gray-50 px-4 sm:px-6 py-3 sm:py-4 flex flex-col sm:flex-row-reverse gap-2">
                        <button
                            type="button"
                            @click="handleSubmit"
                            :disabled="!selectedRole"
                            class="w-full sm:w-auto px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg bg-[#034328] text-white font-semibold hover:bg-[#024024] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            aria-label="Join now with selected role"
                        >
                            Join Now
                        </button>
                        <button
                            type="button"
                            @click="closePopup(true)"
                            class="w-full sm:w-auto px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg bg-white text-gray-700 font-semibold border border-gray-300 hover:bg-gray-50 transition-colors"
                            aria-label="Close popup and join later"
                        >
                            Maybe Later
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>
