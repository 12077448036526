import { ref, onMounted } from 'vue';

export const useLocation = () => {
    const countryCode = ref(null);
    const cityCode = ref(null);
    const cityName = ref(null);
    const latitude = ref(null);
    const longitude = ref(null);
    const loading = ref(true);
    const error = ref(null);

    // Cache key and expiration time (1 day = 86400 seconds)
    const CACHE_KEY = 'cachedLocationData';
    const CACHE_EXPIRATION_TIME = 86400 * 1000; // 1 day in milliseconds

    // Function to check if cached data is still valid
    const getCachedLocation = () => {
        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (Date.now() - timestamp < CACHE_EXPIRATION_TIME) {
                return data;
            }
        }
        return null;
    };

    // Function to cache the detected location
    const cacheLocation = (locationData) => {
        const dataToCache = {
            data: locationData,
            timestamp: Date.now()
        };
        localStorage.setItem(CACHE_KEY, JSON.stringify(dataToCache));
    };

    const detectLocation = async () => {
        try {
            loading.value = true;

            // Check if we have a valid cached location
            const cachedLocation = getCachedLocation();
            if (cachedLocation) {
                countryCode.value = cachedLocation.countryCode;
                cityCode.value = cachedLocation.cityCode;
                cityName.value = cachedLocation.cityName;
                latitude.value = cachedLocation.latitude;
                longitude.value = cachedLocation.longitude;
                return;
            }

            // Try getting precise coordinates from browser first
            const position = await new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(resolve, reject, {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    });
                } else {
                    reject(new Error('Geolocation not supported'));
                }
            });

            if (position) {
                latitude.value = position.coords.latitude;
                longitude.value = position.coords.longitude;
            }

            // Fetch additional location details from IP API
            const response = await fetch('https://ipapi.co/json/');
            const data = await response.json();

            const locationData = {
                countryCode: data.country_code,
                cityCode: data.city?.toLowerCase().replace(/\s+/g, '-'),
                cityName: data.city,
                latitude: latitude.value || data.latitude,
                longitude: longitude.value || data.longitude
            };

            // Update reactive refs
            countryCode.value = locationData.countryCode;
            cityCode.value = locationData.cityCode;
            cityName.value = locationData.cityName;
            latitude.value = locationData.latitude;
            longitude.value = locationData.longitude;

            // Cache the results
            cacheLocation(locationData);

        } catch (e) {
            error.value = 'Could not detect location';
            console.error('Location detection error:', e);
        } finally {
            loading.value = false;
        }
    };

    onMounted(() => {
        detectLocation();
    });

    return {
        countryCode,
        cityCode,
        cityName,
        latitude,
        longitude,
        loading,
        error,
        detectLocation
    };
};

export default useLocation;
