<!-- src/Components/InfoPanel.vue -->
<script setup>
import bannerImage from '@/../assets/images/banner.png';

const features = [
    {
        icon: 'check-circle',
        title: 'Professional Support',
        description: 'Professional and experienced travel companions available 24/7'
    },
    {
        icon: 'user-shield',
        title: 'Personalized Care',
        description: 'Personalized assistance for your specific needs'
    },
    {
        icon: 'heart',
        title: 'Safe Travel',
        description: 'Safe and comfortable travel experience guaranteed'
    }
];

const contactInfo = {
    phone: '+1-(417)-900-3248',
    availability: 'Available 24/7 for urgent inquiries'
};
</script>

<template>
    <div class="w-full h-full">
        <div class="w-full bg-white rounded-2xl overflow-hidden h-full flex flex-col shadow-xl hover:shadow-2xl transition-shadow duration-300">
            <!-- Banner Image -->
            <div class="relative w-full h-64 overflow-hidden">
                <img
                    :src="bannerImage"
                    alt="Travel Assistant"
                    class="w-full h-full object-cover transform hover:scale-105 transition-transform duration-700"
                />
                <div class="absolute inset-0 bg-gradient-to-t from-white via-transparent to-transparent"></div>
            </div>

            <!-- Features Section -->
            <div class="p-8 flex-grow">
                <h2 class="text-2xl font-bold text-primary mb-6 flex items-center gap-3">
                    <i class="fas fa-star text-green-500"></i>
                    Why Choose Our Travel Assistance?
                </h2>

                <div class="space-y-6">
                    <div
                        v-for="feature in features"
                        :key="feature.title"
                        class="group flex items-start gap-4 p-4 rounded-xl hover:bg-green-50 transition-colors duration-300"
                    >
                        <div class="flex-shrink-0">
                            <div class="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center group-hover:bg-green-200 transition-colors duration-300">
                                <i :class="`fas fa-${feature.icon} text-green-600`"></i>
                            </div>
                        </div>
                        <div>
                            <h3 class="font-semibold text-gray-800 mb-1">{{ feature.title }}</h3>
                            <p class="text-gray-600">{{ feature.description }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Contact Section -->
            <div class="px-8 pb-8">
                <div class="bg-gradient-to-br from-green-50 to-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
                    <div class="flex items-center gap-4 mb-4">
                        <div class="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
                            <i class="fas fa-phone text-green-600"></i>
                        </div>
                        <div>
                            <h3 class="font-semibold text-gray-800">Need Immediate Assistance?</h3>
                            <p class="text-green-600 font-bold">{{ contactInfo.phone }}</p>
                        </div>
                    </div>
                    <p class="text-sm text-gray-500">{{ contactInfo.availability }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
