<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    length: {
        type: Number,
        default: 6
    }
});

const emit = defineEmits(['update:modelValue']);

const otpValues = ref(Array(props.length).fill(''));
const inputs = ref([]);

// Watch for external value changes
watch(() => props.modelValue, (newVal) => {
    if (newVal) {
        const values = newVal.split('');
        otpValues.value = Array(props.length).fill('').map((_, i) => values[i] || '');
    }
});

// Update the model value when OTP changes
watch(otpValues, (newVal) => {
    emit('update:modelValue', newVal.join(''));
}, { deep: true });

const focusNext = (index) => {
    if (index < props.length - 1 && otpValues.value[index]) {
        inputs.value[index + 1].focus();
    }
};

const focusPrev = (index) => {
    if (index > 0) {
        inputs.value[index - 1].focus();
    }
};

const handleInput = (index, event) => {
    const value = event.target.value;
    // Only keep the last character if multiple characters are pasted/entered
    otpValues.value[index] = value.slice(-1);
    if (value) focusNext(index);
};

const handleKeydown = (index, event) => {
    if (event.key === 'Backspace' && !otpValues.value[index]) {
        focusPrev(index);
    }
};

const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    const values = pastedData.slice(0, props.length).split('');
    otpValues.value = Array(props.length).fill('').map((_, i) => values[i] || '');
    if (values.length > 0) {
        inputs.value[Math.min(values.length, props.length) - 1].focus();
    }
};
</script>

<template>
    <div class="flex justify-center gap-2">
        <template v-for="(digit, index) in length" :key="index">
            <input
                ref="inputs"
                type="text"
                maxlength="1"
                v-model="otpValues[index]"
                class="w-12 h-12 text-center text-xl border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                @input="handleInput(index, $event)"
                @keydown="handleKeydown(index, $event)"
                @paste="handlePaste"
            />
        </template>
    </div>
</template> 