<!-- resources/js/Components/LanguageSelector.vue -->
<template>
    <div class="relative language-selector">
        <button 
            ref="selectorRef"
            @click="toggleDropdown"
            class="flex items-center gap-1.5 px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:bg-gray-50 transition-colors"
        >
            <component :is="icons.Globe" class="w-4 h-4" v-if="icons.Globe" />
            <span>{{ currentLanguage.label }}</span>
            <component :is="icons.ChevronDown" class="w-4 h-4" :class="{ 'rotate-180': isOpen }" v-if="icons.ChevronDown" />
        </button>

        <!-- Desktop Dropdown -->
        <Transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div v-show="isOpen && !isMobile" 
                 class="absolute left-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                 :style="dropdownPosition">
                <div class="p-2">
                    <div class="relative mb-2">
                        <input 
                            v-model="searchQuery"
                            type="text"
                            placeholder="Search language..."
                            class="w-full pl-8 pr-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-[#2B8B6F] focus:border-transparent"
                        >
                        <component :is="icons.Search" class="w-4 h-4 text-gray-400 absolute left-2.5 top-1/2 transform -translate-y-1/2" v-if="icons.Search" />
                    </div>
                    <div class="max-h-[300px] overflow-y-auto custom-scrollbar">
                        <button
                            v-for="lang in filteredLanguages"
                            :key="lang.code"
                            @click="selectLanguage(lang.code)"
                            class="block w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors rounded-md"
                            :class="{ 'bg-gray-50 text-[#2B8B6F]': lang.code === locale }"
                        >
                            <div class="flex items-center gap-2">
                                <div class="flex flex-col min-w-0">
                                    <span class="font-medium truncate">{{ lang.label }}</span>
                                    <span class="text-xs text-gray-500 truncate">{{ lang.native }}</span>
                                </div>
                                <component :is="icons.Check" v-if="lang.code === locale" class="w-4 h-4 ml-auto text-[#2B8B6F]" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Transition>

        <!-- Mobile Full Screen -->
        <Transition
            enter-active-class="transition-opacity duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-if="isOpen && isMobile" 
                 class="fixed inset-0 bg-white z-50">
                <div class="h-full flex flex-col">
                    <!-- Header -->
                    <div class="flex items-center px-4 py-3 border-b">
                        <h3 class="text-lg font-semibold text-gray-900">Select Language</h3>
                        <button @click="closeDropdown" 
                                class="p-2 rounded-full hover:bg-gray-100 transition-colors ml-auto">
                            <component :is="icons.X" class="w-5 h-5 text-gray-500" v-if="icons.X" />
                        </button>
                    </div>

                    <!-- Search -->
                    <div class="px-4 py-3 border-b">
                        <div class="relative">
                            <input 
                                v-model="searchQuery"
                                type="text"
                                placeholder="Search language..."
                                class="w-full pl-10 pr-4 py-2.5 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2B8B6F] focus:border-transparent"
                            >
                            <component :is="icons.Search" class="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" v-if="icons.Search" />
                        </div>
                    </div>

                    <!-- Language List -->
                    <div class="flex-1 overflow-y-auto custom-scrollbar px-4">
                        <button
                            v-for="lang in filteredLanguages"
                            :key="lang.code"
                            @click="selectLanguage(lang.code)"
                            class="w-full text-left px-3 py-3 hover:bg-gray-50 transition-colors rounded-lg"
                            :class="{ 'bg-gray-50': lang.code === locale }"
                        >
                            <div class="flex items-center gap-3">
                                <span class="text-2xl">{{ lang.flag }}</span>
                                <div class="flex flex-col min-w-0">
                                    <span class="text-base font-medium text-gray-900 truncate">{{ lang.label }}</span>
                                    <span class="text-sm text-gray-500 truncate">{{ lang.native }}</span>
                                </div>
                                <component :is="icons.Check" v-if="lang.code === locale" class="w-5 h-5 ml-auto text-[#2B8B6F]" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { loadLanguageIcons } from '@/Utils/dynamicImports';
import { loadLanguage } from '@/i18n';

const isOpen = ref(false);
const searchQuery = ref('');
const isMobile = ref(window.innerWidth < 768);
const selectorRef = ref(null);
const { locale } = useI18n();
const icons = ref({});

// Add new refs for positioning
const dropdownPosition = ref({});

const languages = [
    // Global & European Languages
    { code: 'en', label: 'English', native: 'English', flag: '🇬🇧' },
    { code: 'es', label: 'Spanish', native: 'Español', flag: '🇪🇸' },
    { code: 'fr', label: 'French', native: 'Français', flag: '🇫🇷' },
    { code: 'it', label: 'Italian', native: 'Italiano', flag: '🇮🇹' },
    { code: 'pt', label: 'Portuguese', native: 'Português', flag: '🇵🇹' },
    { code: 'ru', label: 'Russian', native: 'Русский', flag: '🇷🇺' },
    { code: 'el', label: 'Greek', native: 'Ελληνικά', flag: '🇬🇷' },
    
    // Asian Languages
    { code: 'zh', label: 'Chinese', native: '中文', flag: '🇨🇳' },
    
    // Indian Languages
    { code: 'hi', label: 'Hindi', native: 'हिंदी', flag: '🇮🇳' },
    { code: 'bn', label: 'Bengali', native: 'বাংলা', flag: '🇧🇩' },
    { code: 'pa', label: 'Punjabi', native: 'ਪੰਜਾਬੀ', flag: '🇮🇳' },
    { code: 'gu', label: 'Gujarati', native: 'ગુજરાતી', flag: '🇮🇳' },
    { code: 'ml', label: 'Malayalam', native: 'മലയാളം', flag: '🇮🇳' }
];

const currentLanguage = computed(() => {
    return languages.find(lang => lang.code === locale.value) || languages[0];
});

const filteredLanguages = computed(() => {
    if (!searchQuery.value) return languages;
    const query = searchQuery.value.toLowerCase();
    return languages.filter(lang => 
        lang.label.toLowerCase().includes(query) || 
        lang.code.toLowerCase().includes(query)
    );
});

const selectLanguage = async (langCode) => {
    await loadLanguage(langCode);
    localStorage.setItem('language', langCode);
    closeDropdown();
};

const toggleDropdown = () => {
    isOpen.value = !isOpen.value;
    if (isOpen.value) {
        updateDropdownPosition();
        if (isMobile.value) {
            document.body.style.overflow = 'hidden';
        }
    } else {
        closeDropdown();
    }
};

const closeDropdown = () => {
    isOpen.value = false;
    searchQuery.value = '';
    document.body.style.overflow = '';
};

// Function to calculate dropdown position
const updateDropdownPosition = () => {
    if (!selectorRef.value || isMobile.value) return;
    
    const rect = selectorRef.value.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;
    
    if (spaceBelow < 350 && spaceAbove > spaceBelow) {
        dropdownPosition.value = {
            bottom: '100%',
            marginBottom: '0.5rem',
            top: 'auto'
        };
    } else {
        dropdownPosition.value = {
            top: '100%',
            marginTop: '0.5rem',
            bottom: 'auto'
        };
    }
};

// Handle window resize
const handleResize = () => {
    isMobile.value = window.innerWidth < 768;
    if (isOpen.value) {
        updateDropdownPosition();
    }
};

// Close dropdown when clicking outside
const closeOnClickOutside = (event) => {
    if (!event.target.closest('.language-selector')) {
        closeDropdown();
    }
};

onMounted(async () => {
    // Load icons
    icons.value = await loadLanguageIcons();
    
    // Load saved language
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && savedLanguage !== 'en') {
        await loadLanguage(savedLanguage);
    }
    
    window.addEventListener('resize', handleResize);
    document.addEventListener('click', closeOnClickOutside);
    window.addEventListener('scroll', updateDropdownPosition);
});

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
    document.removeEventListener('click', closeOnClickOutside);
    window.removeEventListener('scroll', updateDropdownPosition);
    document.body.style.overflow = '';
});
</script>

<style scoped>
.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #CBD5E1 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #CBD5E1;
    border-radius: 3px;
}
</style>
