<script setup>
import { ref } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';
import PhoneInput from './PhoneInput.vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const showSuccess = ref(false);
const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: '',
    request_type: 'general_query',
    terms_accepted: false
}, {
    preserveScroll: true,
    preserveState: true
});



const submit = () => {
    form.post(route('contact.request.store'), {
        preserveScroll: true,
        onSuccess: () => {
            showSuccess.value = true;
            form.reset();
            // setTimeout(() => {
            //     showSuccess.value = false;
            // }, 5000);
        },
    });
};

const closeSuccess = () => {
    showSuccess.value = false;
    form.reset();
};
</script>

<template>

    <div class="w-full h-full p-8 relative">
        <!-- Decorative Elements -->
        <div class="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#034328]/5 to-[#034328]/10 rounded-full blur-3xl"></div>
        <div class="absolute bottom-0 left-0 w-32 h-32 bg-gradient-to-tr from-[#034328]/5 to-[#034328]/10 rounded-full blur-3xl"></div>

        <!-- Form Container -->
        <div class="relative">
            <!-- Enhanced Form -->
            <form v-if="!showSuccess" @submit.prevent="submit" class="space-y-6 relative">
                <!-- Name Fields -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700 mb-1 block">{{ t('contact.form.firstName') }}</label>
                        <input
                            v-model="form.first_name"
                            type="text"
                            :placeholder="t('contact.form.placeholder.firstName')"
                            class="w-full p-3 bg-white rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#034328]/30 focus:border-transparent transition-all duration-300 shadow-sm group-hover:shadow-md placeholder-gray-400"
                            required
                            :disabled="form.processing"
                        />
                        <div v-if="form.errors.first_name" class="absolute -bottom-5 left-0 text-red-500 text-sm">
                            {{ form.errors.first_name }}
                        </div>
                    </div>

                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700 mb-1 block">{{ t('contact.form.lastName') }}</label>
                        <input
                            v-model="form.last_name"
                            type="text"
                            :placeholder="t('contact.form.placeholder.lastName')"
                            class="w-full p-3 bg-white rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#034328]/30 focus:border-transparent transition-all duration-300 shadow-sm group-hover:shadow-md placeholder-gray-400"
                            required
                            :disabled="form.processing"
                        />
                        <div v-if="form.errors.last_name" class="absolute -bottom-5 left-0 text-red-500 text-sm">
                            {{ form.errors.last_name }}
                        </div>
                    </div>
                </div>

                <!-- Contact Fields -->
                <div class="grid grid-cols-1 gap-6">
                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700 mb-1 block">{{ t('contact.form.email') }}</label>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-3 flex items-center">
                                <i class="fas fa-envelope text-gray-400"></i>
                            </div>
                            <input
                                v-model="form.email"
                                type="email"
                                placeholder="your.email@example.com"
                                class="w-full pl-10 p-3 bg-white rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#034328]/30 focus:border-transparent transition-all duration-300 shadow-sm group-hover:shadow-md placeholder-gray-400"
                                required
                                :disabled="form.processing"
                            />
                        </div>
                        <div v-if="form.errors.email" class="absolute -bottom-5 left-0 text-red-500 text-sm">
                            {{ form.errors.email }}
                        </div>
                    </div>

                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700 mb-1 block">{{ t('contact.form.phone') }}</label>
                        <PhoneInput
                            v-model="form.phone"
                            :error="form.errors.phone"
                            class="shadow-sm group-hover:shadow-md"
                            :disabled="form.processing"
                        />
                    </div>
                </div>

                <!-- Request Type -->
                <div class="relative group">
                    <label class="text-sm font-medium text-gray-700 mb-1 block">{{ t('contact.form.requestType.label') }}</label>
                    <select
                        v-model="form.request_type"
                        class="w-full p-3 bg-white rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#034328]/30 focus:border-transparent transition-all duration-300 shadow-sm group-hover:shadow-md"
                        :disabled="form.processing"
                    >
                        <option value="general_query">{{ t('contact.form.requestType.options.general') }}</option>
                        <option value="support">{{ t('contact.form.requestType.options.support') }}</option>
                        <option value="business">{{ t('contact.form.requestType.options.business') }}</option>
                        <option value="feedback">{{ t('contact.form.requestType.options.feedback') }}</option>
                    </select>
                </div>

                <!-- Message -->
                <div class="relative group">
                    <label class="text-sm font-medium text-gray-700 mb-1 block">{{ t('contact.form.message') }}</label>
                    <textarea
                        v-model="form.message"
                        rows="4"
                        :placeholder="t('contact.form.placeholder.message')"
                        class="w-full p-3 bg-white rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#034328]/30 focus:border-transparent transition-all duration-300 shadow-sm group-hover:shadow-md resize-none placeholder-gray-400"
                        required
                        :disabled="form.processing"
                    ></textarea>
                </div>

                <!-- Terms Acceptance -->
                <div class="flex items-start space-x-3">
                    <div class="flex items-center h-5">
                        <input
                            v-model="form.terms_accepted"
                            type="checkbox"
                            required
                            :disabled="form.processing"
                            class="h-4 w-4 text-[#034328] focus:ring-[#034328]/30 border-gray-300 rounded transition-all duration-300"
                        />
                    </div>
                    <div class="text-sm">
                        <label class="font-medium text-gray-700">
                            {{ t('contact.form.terms.agree') }}
                            <Link href="/legal/user-terms-and-conditions" class="text-[#034328]/80 hover:text-[#034328] transition-colors duration-300">
                                {{ t('contact.form.terms.termsService') }}
                            </Link>
                            {{ t('contact.form.terms.and') }}
                            <Link href="/legal/privacy-policy" class="text-[#034328]/80 hover:text-[#034328] transition-colors duration-300">
                                {{ t('contact.form.terms.privacyPolicy') }}
                            </Link>
                        </label>
                    </div>
                </div>

                <!-- Submit Button -->
                <div>
                    <button
                        type="submit"
                        :disabled="form.processing"
                        class="w-full group relative flex justify-center py-3 px-4 rounded-xl text-base font-medium text-white bg-gradient-to-r from-[#034328] to-[#034328]/80 hover:from-[#034328]/90 hover:to-[#034328]/70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#034328]/30 transition-all duration-300 shadow-lg hover:shadow-xl disabled:opacity-70 disabled:cursor-not-allowed"
                    >
                        <span v-if="form.processing" class="flex items-center">
                            <i class="fas fa-circle-notch fa-spin mr-2"></i>
                            {{ t('contact.form.processing') }}
                        </span>
                        <span v-else class="flex items-center">
                            {{ t('contact.form.submit') }}
                            <i class="fas fa-paper-plane ml-2 group-hover:translate-x-1 transition-transform duration-300"></i>
                        </span>
                    </button>
                </div>
            </form>
        </div>

        <!-- Success Modal -->
        <TransitionRoot appear :show="showSuccess" as="template">
            <Dialog as="div" @close="closeSuccess" class="relative z-50">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-black/30 backdrop-blur-sm"></div>
                </TransitionChild>

                <div class="fixed inset-0 overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4">
                        <TransitionChild
                            as="template"
                            enter="duration-300 ease-out"
                            enter-from="opacity-0 scale-95"
                            enter-to="opacity-100 scale-100"
                            leave="duration-200 ease-in"
                            leave-from="opacity-100 scale-100"
                            leave-to="opacity-0 scale-95"
                        >
                            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                                    {{ t('contact.form.success.title') }}
                                </DialogTitle>

                                <div class="mt-2">
                                    <p class="text-sm text-gray-500">
                                        {{ t('contact.form.success.description') }}
                                    </p>
                                </div>

                                <div class="mt-4">
                                    <button
                                        type="button"
                                        class="inline-flex justify-center rounded-md border border-transparent bg-[#034328]/10 px-4 py-2 text-sm font-medium text-[#034328] hover:bg-[#034328]/20 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#034328]/30 focus-visible:ring-offset-2"
                                        @click="closeSuccess"
                                    >
                                        {{ t('contact.form.success.button') }}
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>

</template>

<style scoped>
@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fade-in {
    animation: fade-in 0.5s ease-out forwards;
}
</style>
