<template>
  <Teleport to="body">
    <Transition
      enter-active-class="transition ease-out duration-300"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-if="modelValue" 
          class="fixed inset-0 z-[9999] flex items-center justify-center bg-black/80 backdrop-blur-[2px]"
          @click="$emit('update:modelValue', false)">
        <div class="relative w-full max-w-5xl mx-4 transform motion-safe:animate-modal-appear" @click.stop>
          <button 
            @click="$emit('update:modelValue', false)"
            class="absolute -top-12 right-0 text-white hover:text-gray-300 transition-colors motion-safe:animate-fade-in"
            aria-label="Close video">
            <X class="w-8 h-8" aria-hidden="true" />
          </button>
          <div class="relative pt-[56.25%] rounded-xl overflow-hidden shadow-2xl motion-safe:animate-scale-up">
            <iframe
              ref="videoPlayer"
              class="absolute inset-0 w-full h-full rounded-xl"
              :src="videoUrl"
              :title="title"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, watch, onUnmounted } from 'vue'
import { X } from 'lucide-vue-next'

const props = defineProps<{
  modelValue: boolean
  videoUrl: string
  title: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

// Handle escape key
const handleKeydown = (e: KeyboardEvent) => {
  if (e.key === 'Escape' && props.modelValue) {
    emit('update:modelValue', false)
  }
}

// Add/remove event listeners
watch(() => props.modelValue, (newVal) => {
  if (newVal) {
    document.body.style.overflow = 'hidden'
    window.addEventListener('keydown', handleKeydown)
  } else {
    document.body.style.overflow = ''
    window.removeEventListener('keydown', handleKeydown)
  }
})

// Cleanup on unmount
onUnmounted(() => {
  window.removeEventListener('keydown', handleKeydown)
  document.body.style.overflow = ''
})
</script>

<style scoped>
@keyframes modal-appear {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-modal-appear {
  animation: modal-appear 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.animate-scale-up {
  animation: scale-up 0.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.animate-fade-in {
  animation: fade-in 0.3s ease-out 0.1s both;
}
</style> 