<template>
    <footer :style="{
        backgroundImage: `url(${footerImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }" class="relative overflow-hidden">
      <div class="absolute inset-0 bg-gradient-to-b from-black/60 to-black/90"></div>
      <div class="relative bg-black/40 px-6 md:px-20 py-8 md:py-16 text-white">
        <div class="absolute opacity-10 w-96 h-96 rounded-full bg-[#2B8B6F] blur-3xl -top-24 -left-24 animate-pulse"></div>
        <div class="absolute opacity-10 w-96 h-96 rounded-full bg-[#2B8B6F] blur-3xl -bottom-24 -right-24 animate-pulse"></div>
        
        <div class="text-center mb-8 md:mb-16 relative">
          <h2 class="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/80 whitespace-pre-line mx-auto leading-normal">{{ t('footer.description') }}</h2>
          <p class="text-white/80 mb-8 max-w-xl mx-auto">
            Care in the Air!
          </p>
          <Link href="/register" class="group w-full md:w-auto px-8 py-4 bg-gradient-to-r from-[#2B8B6F] to-[#2B8B6F]/80 text-white rounded-full inline-block transition-all duration-300 hover:shadow-lg hover:shadow-[#2B8B6F]/20 hover:-translate-y-1">
            <span class="group-hover:mr-2 transition-all">{{ t('common.joinNow') }}</span>
            <span class="transform translate-x-0 inline-block group-hover:translate-x-1 transition-transform">→</span>
          </Link>
        </div>
  
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 py-8 md:py-16 border-t border-b border-white/20 justify-between relative">
          <div class="sm:col-span-1">
            <h3 class="font-medium mb-4 relative inline-block">
              {{ t('contact.banner.title') }}
              <span class="absolute -bottom-1 left-0 w-0 h-0.5 bg-[#2B8B6F] group-hover:w-full transition-all duration-300"></span>
            </h3>
            <div class="space-y-2 text-white/80">
              <p v-if="loading" class="flex items-center">
                <i class="fas fa-circle-notch fa-spin mr-2"></i>
                {{ t('footer.stats.loading') }}
              </p>
              <p v-else class="hover:text-[#2B8B6F] transition-all duration-300 cursor-pointer flex items-center">
                <PhoneCall class="w-4 h-4 mr-2 opacity-70" />
                {{ contactInfo.phone }}
              </p>
              <p class="text-xs text-white/60 italic ml-6 -mt-1">{{ t('footer.numberRegion') }}</p>
              <p class="mt-4 hover:text-[#2B8B6F] transition-all duration-300 cursor-pointer flex items-center">
                <Mail class="w-4 h-4 mr-2 opacity-70" />
                {{ contactInfo.email }}
              </p>
            </div>
          </div>
          <div class="sm:col-span-1">
            <h3 class="font-medium mb-4 relative inline-block">
              {{ t('footer.quickLinks') }}
              <span class="absolute -bottom-1 left-0 w-0 h-0.5 bg-[#2B8B6F] group-hover:w-full transition-all duration-300"></span>
            </h3>
            <div class="space-y-2 text-white/80">
              <p>
                <Link href="/about" class="hover:text-[#2B8B6F] transition-all duration-300 inline-flex items-center hover:translate-x-1">
                  <ExternalLink class="w-4 h-4 mr-2 opacity-60" /> 
                  {{ t('nav.about') }}
                </Link>
              </p>
              <p>
                <Link href="/service-packages" class="hover:text-[#2B8B6F] transition-all duration-300 inline-flex items-center hover:translate-x-1">
                  <Package class="w-4 h-4 mr-2 opacity-60" /> 
                  {{ t('nav.services') }}
                </Link>
              </p>
              <p>
                <Link href="/contact" class="hover:text-[#2B8B6F] transition-all duration-300 inline-flex items-center hover:translate-x-1">
                  <Phone class="w-4 h-4 mr-2 opacity-60" /> 
                  {{ t('nav.contact') }}
                </Link>
              </p>
              <p>
                <Link href="/faq" class="hover:text-[#2B8B6F] transition-all duration-300 inline-flex items-center hover:translate-x-1">
                  <HelpCircle class="w-4 h-4 mr-2 opacity-60" /> 
                  {{ t('footer.faq') }}
                </Link>
              </p>
            </div>
          </div>
          <div class="sm:col-span-1">
            <h3 class="font-medium mb-4 relative inline-block">
              Legal
              <span class="absolute -bottom-1 left-0 w-0 h-0.5 bg-[#2B8B6F] group-hover:w-full transition-all duration-300"></span>
            </h3>
            <div class="space-y-2 text-white/80">
              <p>
                <Link href="/legal/privacy-policy" class="hover:text-[#2B8B6F] transition-all duration-300 inline-flex items-center hover:translate-x-1">
                  <Shield class="w-4 h-4 mr-2 opacity-60" /> 
                  {{ t('footer.privacyPolicy') }}
                </Link>
              </p>
              <p>
                <Link href="/legal/user-terms-and-conditions" class="hover:text-[#2B8B6F] transition-all duration-300 inline-flex items-center hover:translate-x-1">
                  <FileText class="w-4 h-4 mr-2 opacity-60" /> 
                  {{ t('footer.userTerms') }}
                </Link>
              </p>
              <p>
                <Link href="/legal/carer-terms-and-conditions" class="hover:text-[#2B8B6F] transition-all duration-300 inline-flex items-center hover:translate-x-1">
                  <FileCheck class="w-4 h-4 mr-2 opacity-60" /> 
                  {{ t('footer.carerTerms') }}
                </Link>
              </p>
              <p>
                <Link href="/legal/care-seeker-terms-and-conditions" class="hover:text-[#2B8B6F] transition-all duration-300 inline-flex items-center hover:translate-x-1">
                  <FileCheck class="w-4 h-4 mr-2 opacity-60" /> 
                  {{ t('footer.careSeekerTerms') }}
                </Link>
              </p>
            </div>
          </div>
          <div class="sm:col-span-1">
            <h3 class="font-medium mb-4 relative inline-block">
              {{ t('footer.getOurApp') }}
              <span class="absolute -bottom-1 left-0 w-0 h-0.5 bg-[#2B8B6F] group-hover:w-full transition-all duration-300"></span>
            </h3>
            <p class="text-white/80 mb-4">{{ t('footer.appDescription') }}</p>
            <div class="space-y-2">
              <button class="w-full px-6 py-2 bg-gradient-to-r from-[#1E1E1E]/40 to-[#1E1E1E]/20 hover:from-[#2B8B6F]/20 hover:to-[#2B8B6F]/10 rounded-xl text-white transition-all duration-300 hover:shadow-lg hover:shadow-[#2B8B6F]/10">
                {{ t('footer.comingSoon') }}
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-col md:flex-row justify-center items-center gap-4 pt-8 md:pt-12 relative">
            <div class="flex gap-4 justify-center flex-wrap mx-auto">
              <a 
                href="https://www.linkedin.com/company/buddy-4-travel" 
                target="_blank" 
                rel="noopener noreferrer" 
                class="w-12 h-12 bg-[#0077B5]/20 hover:bg-[#0077B5]/40 rounded-xl flex items-center justify-center transition-all duration-300 hover:scale-110 hover:shadow-lg"
                :aria-label="t('footer.social.linkedin')"
              >
                <Linkedin class="w-6 h-6" />
              </a>
              <a 
                href="https://www.youtube.com/@buddy4travel" 
                target="_blank" 
                rel="noopener noreferrer" 
                class="w-12 h-12 bg-[#FF0000]/20 hover:bg-[#FF0000]/40 rounded-xl flex items-center justify-center transition-all duration-300 hover:scale-110 hover:shadow-lg"
                :aria-label="t('footer.social.youtube')"
              >
                <Youtube class="w-6 h-6" />
              </a>
              <a 
                href="http://facebook.com/b4t.buddy4travel" 
                target="_blank" 
                rel="noopener noreferrer" 
                class="w-12 h-12 bg-[#1877F2]/20 hover:bg-[#1877F2]/40 rounded-xl flex items-center justify-center transition-all duration-300 hover:scale-110 hover:shadow-lg"
                :aria-label="t('footer.social.facebook')"
              >
                <Facebook class="w-6 h-6" />
              </a>
              <a 
                href="https://www.instagram.com/b4t.buddy4travel" 
                target="_blank" 
                rel="noopener noreferrer" 
                class="w-12 h-12 bg-[#E4405F]/20 hover:bg-[#E4405F]/40 rounded-xl flex items-center justify-center transition-all duration-300 hover:scale-110 hover:shadow-lg"
                :aria-label="t('footer.social.instagram')"
              >
                <Instagram class="w-6 h-6" />
              </a>
              <a 
                href="https://x.com/VictoriaTa33904" 
                target="_blank" 
                rel="noopener noreferrer" 
                class="w-12 h-12 bg-[#1DA1F2]/20 hover:bg-[#1DA1F2]/40 rounded-xl flex items-center justify-center transition-all duration-300 hover:scale-110 hover:shadow-lg"
                :aria-label="t('footer.social.threads')"
              >
                <Twitter class="w-6 h-6" />
              </a>
            </div>
          </div>
  
        <div class="flex flex-col md:flex-row justify-between items-center gap-6 pt-8 md:pt-12 text-sm relative">
          <div class="flex items-center justify-center w-full md:w-auto transition-all duration-300 hover:scale-105">
            <img :src="logoImage" alt="Buddy 4 Travel" class="h-8 md:h-12">
          </div>
          <div class="text-white/60 text-center w-full md:w-auto">{{ t('footer.copyright') }}</div>
          <div class="text-center w-full md:w-auto md:text-right">
            {{ t('footer.madeWith') }} <span class="text-[#2B8B6F] animate-pulse">♥</span> {{ t('footer.forTravelers') }}
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script setup lang="ts">
  import { Link } from '@inertiajs/vue3'
  import { Linkedin, Youtube, Facebook, Instagram, Twitter, PhoneCall, Mail, ExternalLink, Package, Phone, HelpCircle, Shield, FileText, FileCheck } from 'lucide-vue-next'
  import { ref, computed } from 'vue'
  import { useLocation } from '../Composables/useLocation'
  import { getContactByCountry } from '../Utils/contactInfo'
  import { useI18n } from 'vue-i18n'
  import footerImage from '~/images/footer_image.webp';
  import logoImage from '~/images/logo.png';

  const { t } = useI18n();

  // Initialize location detection
  const { countryCode, loading, error } = useLocation()

  // Compute contact information based on location
  const contactInfo = computed(() => {
    const defaultContact = {
      phone: '1800-248-785',
      email: 'contact@b4t.com'
    }

    if (loading.value || !countryCode.value) {
      return defaultContact
    }

    const locationBasedContact = getContactByCountry(countryCode.value)
    return {
      phone: locationBasedContact?.phone || defaultContact.phone,
      email: defaultContact.email
    }
  })
  </script> 