<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import Dropdown from '@/Components/Dropdown.vue';
import axios from 'axios';

const searchQuery = ref('');
const showMobileMenu = ref(false);
const selectedCurrency = ref(localStorage.getItem('selectedCurrency') || null);
const conversionRates = ref({});
const loading = ref(true);

// Currency to country mapping (common currencies for countries)
const countryToCurrency = {
    'US': 'USD', // United States
    'GB': 'GBP', // United Kingdom
    'EU': 'EUR', // European Union
    'JP': 'JPY', // Japan
    'AU': 'AUD', // Australia
    'CA': 'CAD', // Canada
    'CH': 'CHF', // Switzerland
    'CN': 'CNY', // China
    'IN': 'INR', // India
    'NZ': 'NZD', // New Zealand
    'SG': 'SGD', // Singapore
    'HK': 'HKD', // Hong Kong
    'AE': 'AED', // UAE
    'TH': 'THB', // Thailand
    'MY': 'MYR', // Malaysia
    'ID': 'IDR', // Indonesia
    'PH': 'PHP', // Philippines
    'KR': 'KRW', // South Korea
    'SA': 'SAR', // Saudi Arabia
    'BR': 'BRL'  // Brazil
};

// Full list of currencies with their symbols and names
const allCurrencies = [
    { code: 'USD', symbol: '$', name: 'US Dollar' },
    { code: 'EUR', symbol: '€', name: 'Euro' },
    { code: 'GBP', symbol: '£', name: 'British Pound' },
    { code: 'JPY', symbol: '¥', name: 'Japanese Yen' },
    { code: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
    { code: 'CAD', symbol: 'C$', name: 'Canadian Dollar' },
    { code: 'CHF', symbol: 'Fr', name: 'Swiss Franc' },
    { code: 'CNY', symbol: '¥', name: 'Chinese Yuan' },
    { code: 'INR', symbol: '₹', name: 'Indian Rupee' },
    { code: 'NZD', symbol: 'NZ$', name: 'New Zealand Dollar' },
    { code: 'SGD', symbol: 'S$', name: 'Singapore Dollar' },
    { code: 'HKD', symbol: 'HK$', name: 'Hong Kong Dollar' },
    { code: 'AED', symbol: 'د.إ', name: 'UAE Dirham' },
    { code: 'THB', symbol: '฿', name: 'Thai Baht' },
    { code: 'MYR', symbol: 'RM', name: 'Malaysian Ringgit' },
    { code: 'IDR', symbol: 'Rp', name: 'Indonesian Rupiah' },
    { code: 'PHP', symbol: '₱', name: 'Philippine Peso' },
    { code: 'KRW', symbol: '₩', name: 'South Korean Won' },
    { code: 'SAR', symbol: '﷼', name: 'Saudi Riyal' },
    { code: 'BRL', symbol: 'R$', name: 'Brazilian Real' }
];

// Filtered currencies based on search
const filteredCurrencies = computed(() => {
    if (!searchQuery.value) return allCurrencies;
    const query = searchQuery.value.toLowerCase();
    return allCurrencies.filter(currency => 
        currency.code.toLowerCase().includes(query) ||
        currency.name.toLowerCase().includes(query)
    );
});

// Detect user's location and set default currency
const detectUserCurrency = async () => {
    try {
        // Use the same IP geolocation service as phone input
        const response = await axios.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code;
        
        // Get currency code for the country, fallback to USD if not found
        const detectedCurrency = countryToCurrency[countryCode] || 'USD';
        
        // Only set if no currency is already selected
        if (!selectedCurrency.value) {
            selectedCurrency.value = detectedCurrency;
            localStorage.setItem('selectedCurrency', detectedCurrency);
            
            // Dispatch currency changed event with the detected currency
            if (conversionRates.value[detectedCurrency]) {
                window.dispatchEvent(new CustomEvent('currency-changed', { 
                    detail: { 
                        currency: detectedCurrency,
                        rate: conversionRates.value[detectedCurrency]
                    } 
                }));
            }
        }
    } catch (error) {
        console.error('Failed to detect user location:', error);
        // Fallback to USD if location detection fails
        if (!selectedCurrency.value) {
            selectedCurrency.value = 'USD';
            localStorage.setItem('selectedCurrency', 'USD');
        }
    }
};

// Fetch conversion rates
const fetchConversionRates = async () => {
    loading.value = true;
    try {
        const response = await axios.get(route('api.currency-rates'));
        conversionRates.value = response.data.rates;
        
        // Store rates in localStorage with metadata
        localStorage.setItem('conversionRates', JSON.stringify({
            rates: response.data.rates,
            lastUpdated: response.data.last_updated_at,
            nextUpdate: response.data.next_update_at
        }));
    } catch (error) {
        console.error('Failed to fetch currency rates:', error);
        const cachedRates = localStorage.getItem('conversionRates');
        if (cachedRates) {
            const parsed = JSON.parse(cachedRates);
            conversionRates.value = parsed.rates;
        }
    } finally {
        loading.value = false;
    }
};

// Check if we need to fetch new rates based on next_update_at
const shouldFetchRates = () => {
    const cachedRates = localStorage.getItem('conversionRates');
    if (!cachedRates) return true;
    
    try {
        const parsed = JSON.parse(cachedRates);
        if (!parsed.nextUpdate) return true;
        
        // Check if we've passed the next update time
        return new Date() >= new Date(parsed.nextUpdate);
    } catch (e) {
        return true;
    }
};

// Change currency and store in localStorage
const changeCurrency = (currency) => {
    selectedCurrency.value = currency;
    localStorage.setItem('selectedCurrency', currency);
    window.dispatchEvent(new CustomEvent('currency-changed', { 
        detail: { 
            currency,
            rate: conversionRates.value[currency] || 1
        } 
    }));
    showMobileMenu.value = false;
};

onMounted(async () => {
    // First load cached rates if available
    const cachedRates = localStorage.getItem('conversionRates');
    if (cachedRates) {
        const parsed = JSON.parse(cachedRates);
        conversionRates.value = parsed.rates;
        loading.value = false;
    }
    
    // Fetch new rates if needed
    if (shouldFetchRates()) {
        await fetchConversionRates();
    } else {
        // Schedule next update
        const parsed = JSON.parse(cachedRates);
        const nextUpdate = new Date(parsed.nextUpdate);
        const now = new Date();
        const delay = Math.max(0, nextUpdate - now);
        
        setTimeout(() => {
            fetchConversionRates();
        }, delay);
    }
    
    // Detect user's currency if not already set
    if (!selectedCurrency.value) {
        await detectUserCurrency();
    }
    
    // Ensure currency changed event is dispatched
    setTimeout(() => {
        window.dispatchEvent(new CustomEvent('currency-changed', { 
            detail: { 
                currency: selectedCurrency.value,
                rate: conversionRates.value[selectedCurrency.value] || 1
            } 
        }));
    }, 100);
});

// Get the current selected currency object
const currentCurrency = computed(() => 
    allCurrencies.find(c => c.code === selectedCurrency.value)
);
</script>

<template>
    <!-- Desktop Dropdown -->
    <div class="hidden sm:block">
        <Dropdown align="right" width="48">
            <template #trigger>
                <button class="flex items-center text-sm font-medium text-white hover:text-emerald-600 hover:bg-emerald-50 rounded-lg px-4 py-2 border border-gray-200 shadow-sm transition-all duration-200">
                    <div class="flex items-center">
                        <span v-if="loading" class="mr-2">
                            <svg class="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        <span v-else class="font-semibold">{{ currentCurrency?.symbol }}</span>
                        <span class="ml-1">{{ selectedCurrency }}</span>
                    </div>
                    <div class="ml-1">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </button>
            </template>

            <template #content>
                <div class="py-1 bg-white rounded-lg shadow-lg border border-gray-100">
                    <div class="px-4 py-2">
                        <input
                            type="text"
                            v-model="searchQuery"
                            placeholder="Search currency..."
                            class="w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                        />
                    </div>
                    <div class="max-h-64 overflow-y-auto">
                        <div v-if="loading" class="px-4 py-2 text-sm text-gray-500 text-center">
                            Loading currencies...
                        </div>
                        <template v-else>
                            <button
                                v-for="currency in filteredCurrencies"
                                :key="currency.code"
                                @click="changeCurrency(currency.code)"
                                class="w-full text-left px-4 py-2 text-sm hover:bg-emerald-50 transition-colors duration-200 flex items-center"
                                :class="{ 'bg-emerald-50 text-emerald-600': selectedCurrency === currency.code }"
                            >
                                <span class="w-6 inline-block font-semibold">{{ currency.symbol }}</span>
                                <span class="ml-2">{{ currency.code }}</span>
                                <span class="ml-2 text-xs text-gray-500">{{ currency.name }}</span>
                            </button>
                        </template>
                    </div>
                </div>
            </template>
        </Dropdown>
    </div>

    <!-- Mobile Menu -->
    <div class="sm:hidden">
        <button 
            @click="showMobileMenu = true"
            class="flex items-center text-sm font-medium text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 rounded-lg px-4 py-2 border border-gray-200 shadow-sm transition-all duration-200"
        >
            <div class="flex items-center">
                <span v-if="loading" class="mr-2">
                    <svg class="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </span>
                <span v-else class="font-semibold">{{ currentCurrency?.symbol }}</span>
                <span class="ml-1">{{ selectedCurrency }}</span>
            </div>
        </button>

        <!-- Mobile Currency Selector Modal -->
        <div v-if="showMobileMenu" class="fixed inset-0 z-50 overflow-hidden">
            <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="showMobileMenu = false"></div>

            <div class="fixed inset-x-0 bottom-0 z-10 bg-white rounded-t-2xl">
                <div class="p-4 border-b border-gray-200">
                    <div class="flex items-center justify-between">
                        <h3 class="text-lg font-semibold text-gray-900">Select Currency</h3>
                        <button @click="showMobileMenu = false" class="text-gray-400 hover:text-gray-500">
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="mt-4">
                        <input
                            type="text"
                            v-model="searchQuery"
                            placeholder="Search currency..."
                            class="w-full px-4 py-3 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                        />
                    </div>
                </div>

                <div class="max-h-[60vh] overflow-y-auto p-2">
                    <div v-if="loading" class="p-4 text-center text-gray-500">
                        Loading currencies...
                    </div>
                    <template v-else>
                        <button
                            v-for="currency in filteredCurrencies"
                            :key="currency.code"
                            @click="changeCurrency(currency.code)"
                            class="w-full text-left p-4 hover:bg-emerald-50 transition-colors duration-200 rounded-xl flex items-center justify-between"
                            :class="{ 'bg-emerald-50': selectedCurrency === currency.code }"
                        >
                            <div class="flex items-center space-x-3">
                                <span class="font-semibold">{{ currency.symbol }}</span>
                                <span>{{ currency.code }}</span>
                                <span class="text-sm text-gray-500">{{ currency.name }}</span>
                            </div>
                            <div v-if="selectedCurrency === currency.code" class="text-emerald-600">
                                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </div>
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template> 