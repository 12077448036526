// utils/contactInfo.js

export const contacts = [
    {
        region: 'USA, Canada',
        phone: '+1 417 900 3248',
        countries: ['US', 'CA'] // USA and Canada
    },
    {
        region: 'United Kingdom',
        phone: '+44 7367 056 248',
        countries: ['GB'] // United Kingdom
    },
    {
        region: 'Europe',
        phone: '+31 970 1027 5248',
        countries: [
            'DE', 'FR', 'IT', 'ES', 'NL', 'BE', 'AT', 'CH',
            'SE', 'NO', 'DK', 'FI', 'PT', 'IE', 'PL'
        ] // Major European countries
    },
    {
        region: 'India',
        phone: '8049251243',
        countries: ['IN'] // India
    },
    {
        region: 'Australia, New Zealand',
        phone: '1800 248 785',
        countries: ['AU', 'NZ'] // Australia and New Zealand
    },
    {
        region: 'All Other Countries',
        phone: '+1 417 900 3248',
        countries: ['*'] // Fallback for unlisted countries
    }
];

/**
 * Helper function to get contact information based on country code
 * @param {string} countryCode - ISO 2-letter country code
 * @returns {Object} Contact information for the matching region
 */
export const getContactByCountry = (countryCode) => {
    // First try to find a specific match
    const specificMatch = contacts.find(contact =>
        contact.countries.includes(countryCode) && !contact.isWhatsapp
    );

    // If no specific match, return the "All Other Countries" contact
    return specificMatch || contacts.find(contact =>
        contact.countries.includes('*') && !contact.isWhatsapp
    );
};

/**
 * Get WhatsApp contact information
 * @returns {Object} WhatsApp contact information
 */
export const getWhatsAppContact = () => contacts.find(contact => contact.isWhatsapp);

export default contacts;
