<!-- src/Components/BookingForm.vue -->
<script setup>
import { ref, computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import PhoneInput from './PhoneInput.vue';
import AirportSearch from './AirportSearch.vue';
import DateRangePicker from './DateRangePicker.vue';
import TimeSelector from './TimeSelector.vue';
import CountrySearch from './CountrySearch.vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import OtpInput from '@/Components/OtpInput.vue';
import axios from 'axios';

const { t } = useI18n();
const emit = defineEmits(['submitted']);

const props = defineProps({
    userType: {
        type: String,
        required: true,
        default: 'Care Seeker'
    },
    initialData: {
        type: Object,
        default: () => ({})
    }
});

const currentStep = ref(0);
const showSuccess = ref(false);
const showOtpModal = ref(false);
const otpCode = ref('');
const isVerifying = ref(false);
const verificationMethod = ref('email');
const otpError = ref('');

const steps = [
    { id: 'personal', title: t('bookingForm.steps.personal'), icon: 'user' },
    { id: 'travel', title: t('bookingForm.steps.travel'), icon: 'plane' },
    { id: 'preferences', title: t('bookingForm.steps.preferences'), icon: 'cog' }
];

// Enhanced form with travel details
const form = useForm({
    firstName: props.initialData.firstName || '',
    lastName: props.initialData.lastName || '',
    contactNumber: props.initialData.contactNumber || '',
    email: props.initialData.email || '',
    locationCountry: props.initialData.locationCountry || '',
    mainFlight: {
        origin: props.initialData.originAirport || '',
        destination: props.initialData.destinationAirport || '',
        travelDate: props.initialData.travelDate || '',
        flightNumber: props.initialData.flightNumber || ''
    },
    layovers: [],
    userType: props.userType,
    preferredContactMode: props.initialData.preferredContactMode || 'email',
    preferredCallTime: props.initialData.preferredCallTime || '',
    enquiryDetails: props.initialData.enquiryDetails || '',
    consent: props.initialData.consent || 'no',
    servicePackage: props.initialData.servicePackage || 'basic',
    age_consent: false,
    privacy_policy_consent: false,
    referralCode: props.initialData?.referralCode || '',
    consent: false,
    is_verified: false,
    verification_type: null,
    medical_conditions: props.initialData.medical_conditions || '',
    special_requirements: props.initialData.special_requirements || ''
});

const enquiryDetailsLength = computed(() => {
    return form.enquiryDetails?.length || 0;
});

const handleSubmit = async () => {
    if (form.processing) return;

    const { isValid, errors } = validateStep();
    if (!isValid) {
        Object.keys(errors).forEach(key => {
            form.setError(key, errors[key]);
        });
        return;
    }

    // Check consents
    if (!form.age_consent || !form.privacy_policy_consent) {
        form.setError('consent', 'Please accept all required consents');
        return;
    }

    // If not on final step, just proceed to next step
    if (currentStep.value < steps.length - 1) {
        nextStep();
        return;
    }

    // On final step, first submit the form without verification
    const formData = {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        contactNumber: form.contactNumber,
        locationCountry: form.locationCountry,
        mainFlight: {
            origin: form.mainFlight.origin,
            destination: form.mainFlight.destination,
            travelDate: form.mainFlight.travelDate,
            flightNumber: form.mainFlight.flightNumber || ''
        },
        layovers: form.layovers,
        userType: form.userType,
        preferredContactMode: form.preferredContactMode,
        preferredCallTime: form.preferredCallTime || '',
        enquiryDetails: form.enquiryDetails || '',
        consent: form.consent,
        servicePackage: form.servicePackage,
        age_consent: form.age_consent,
        privacy_policy_consent: form.privacy_policy_consent,
        referralCode: form.referralCode,
        is_verified: false // Initially save as unverified
    };

    // First save the booking
    form.post(route('booking.enquiry.store'), {
        preserveScroll: true,
        onSuccess: async (response) => {
            // After successful save, send OTP
            try {
                await sendOtp();
            } catch (error) {
                console.error('Failed to send OTP:', error);
                // Show success anyway since booking is saved
                showSuccess.value = true;
                // showOtpModal.value = true;
                emit('submitted', formData);
            }
        },
        onError: (errors) => {
            console.error('Validation errors:', errors);
            if (errors.mainFlight) {
                Object.keys(errors.mainFlight).forEach(key => {
                    form.setError(`mainFlight.${key}`, errors.mainFlight[key]);
                });
            }
            if (errors.layovers) {
                errors.layovers.forEach((layoverErrors, index) => {
                    Object.keys(layoverErrors).forEach(key => {
                        form.setError(`layovers.${index}.${key}`, layoverErrors[key]);
                    });
                });
            }
        }
    });
};

const sendOtp = async () => {
    try {
        const response = await axios.post(route('auth.send-otp'), {
            type: verificationMethod.value,
            email: form.email,
            phone: form.contactNumber
        });
        
        if (response.data.success) {
            showOtpModal.value = true;
            otpError.value = '';
        }
    } catch (error) {
        console.error('Failed to send OTP:', error);
        otpError.value = 'Failed to send verification code';
        throw error; // Re-throw to handle in calling function
    }
};

const verifyOtp = async () => {
    if (otpCode.value.length !== 6) {
        otpError.value = 'Please enter a valid verification code';
        return;
    }

    isVerifying.value = true;
    try {
        const response = await axios.post(route('auth.verify-otp'), {
            code: otpCode.value,
            type: verificationMethod.value,
            email: form.email,
            phone: form.contactNumber
        });
        
        if (response.data.success) {
            showOtpModal.value = false;
            showSuccess.value = true; // Show success after verification
        }
    } catch (error) {
        console.error('OTP verification failed:', error);
        otpError.value = 'Invalid verification code';
    } finally {
        isVerifying.value = false;
    }
};

const closeSuccess = () => {
    showSuccess.value = false;
    window.location.href = route('login');
};

// Add step completion validation
const isStepComplete = computed(() => ({
    personal: !!form.firstName?.trim() && 
             !!form.lastName?.trim() && 
             !!form.email?.trim() && 
             !!form.contactNumber?.trim() &&
             !!form.locationCountry?.trim(),
    travel: !!form.mainFlight.origin?.trim() && 
           !!form.mainFlight.destination?.trim() && 
           !!form.mainFlight.travelDate,
    preferences: !!form.preferredContactMode && form.consent === 'yes'
}));

// Update the validateStep function
const validateStep = () => {
    const errors = {};

    if (currentStep.value === 0) {
        if (!form.firstName?.trim()) errors.firstName = 'First name is required';
        if (!form.lastName?.trim()) errors.lastName = 'Last name is required';
        if (!form.email?.trim()) errors.email = 'Email is required';
        if (!form.contactNumber?.trim()) errors.contactNumber = 'Contact number is required';
        if (!form.locationCountry?.trim()) errors.locationCountry = 'Country is required';
    }

    if (currentStep.value === 1) {
        if (!form.mainFlight.origin?.trim()) errors.origin = 'Origin airport is required';
        if (!form.mainFlight.destination?.trim()) errors.destination = 'Destination airport is required';
        if (!form.mainFlight.travelDate) errors.travelDate = 'Travel date is required';
        
        // Validate layovers if any exist
        form.layovers.forEach((layover, index) => {
            if (!layover.origin?.trim()) errors[`layover_${index}_origin`] = 'Origin required';
            if (!layover.destination?.trim()) errors[`layover_${index}_destination`] = 'Destination required';
            if (!layover.travelDate) errors[`layover_${index}_date`] = 'Date required';
        });
    }

    if (currentStep.value === 2) {
        if (!form.preferredContactMode) errors.preferredContactMode = 'Contact mode is required';
        if (form.consent !== 'yes') errors.consent = 'You must agree to the terms';
    }

    return { isValid: Object.keys(errors).length === 0, errors };
};

// Update the nextStep function
const nextStep = () => {
    const { isValid, errors } = validateStep();
    if (isValid) {
        if (currentStep.value < steps.length - 1) {
            currentStep.value++;
        }
    } else {
        // Handle validation errors
        Object.keys(errors).forEach(key => {
            form.setError(key, errors[key]);
        });
    }
};

// Add previousStep function
const previousStep = () => {
    if (currentStep.value > 0) {
        currentStep.value--;
    }
};

// Add layover management functions
const addLayover = () => {
    form.layovers.push({
        origin: '',
        destination: '',
        travelDate: '',
        flightNumber: ''
    });
};

const removeLayover = (index) => {
    form.layovers.splice(index, 1);
};

// Add error display in the template
const getErrorMessage = (field) => {
    return form.errors[field] || 
           (form.errors.mainFlight && form.errors.mainFlight[field]) || 
           '';
};
</script>

<template>
    <div class="w-full">
        <div class="bg-white rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300">
            <!-- Form Header with Progress -->
            <div class="p-6 border-b border-gray-100">
                <div class="flex items-center justify-between mb-8">
                    <div class="flex items-center gap-4">
                        <div class="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
                            <i class="fas fa-user-plus text-green-600 text-lg"></i>
                        </div>
                        <div>
                            <h2 class="text-2xl font-bold text-gray-800">{{ t('bookingForm.title') }}</h2>
                            <p class="text-gray-500">{{ t('bookingForm.subtitle') }}</p>
                        </div>
                    </div>
                </div>

                <!-- Progress Steps -->
                <div class="flex justify-between relative">
                    <div class="absolute top-5 left-0 h-0.5 bg-gray-200 w-full -z-0"></div>
                    <div
                        class="absolute top-5 left-0 h-0.5 bg-green-500 transition-all duration-500"
                        :style="`width: ${(currentStep / (steps.length - 1)) * 100}%`"
                    ></div>

                    <template v-for="(step, index) in steps" :key="step.id">
                        <div class="flex flex-col items-center relative z-10">
                            <div
                                :class="[
                                    'w-10 h-10 rounded-full flex items-center justify-center transition-all duration-300',
                                    index < currentStep ? 'bg-green-500 text-white' :
                                    index === currentStep ? 'bg-green-100 text-green-600 ring-4 ring-green-50' :
                                    'bg-gray-100 text-gray-400'
                                ]"
                            >
                                <i :class="`fas fa-${step.icon}`"></i>
                            </div>
                            <span
                                class="text-sm mt-2 font-medium"
                                :class="index <= currentStep ? 'text-green-600' : 'text-gray-400'"
                            >
                                {{ step.title }}
                            </span>
                        </div>
                    </template>
                </div>
            </div>

            <!-- Form Content -->
            <form @submit.prevent="handleSubmit" class="p-6 space-y-8">
                <!-- Personal Information Section -->
                <div v-show="currentStep === 0" class="space-y-6">
                    <!-- First Name Input -->
                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700 flex items-center gap-2">
                            {{ t('bookingForm.personal.firstName.label') }}
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="mt-1 relative">
                            <input
                                v-model="form.firstName"
                                type="text"
                                required
                                class="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-green-500
                                       focus:border-transparent transition-all duration-300 pl-11"
                                :placeholder="t('bookingForm.personal.firstName.placeholder')"
                            />
                            <div class="absolute left-4 top-1/2 -translate-y-1/2">
                                <i class="fas fa-user text-gray-400 group-focus-within:text-green-500 transition-colors"></i>
                            </div>
                        </div>
                        <p v-if="getErrorMessage('firstName')" class="mt-1 text-sm text-red-500">
                            {{ getErrorMessage('firstName') }}
                        </p>
                    </div>

                    <!-- Last Name Input -->
                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700 flex items-center gap-2">
                            {{ t('bookingForm.personal.lastName.label') }}
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="mt-1 relative">
                            <input
                                v-model="form.lastName"
                                type="text"
                                required
                                class="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-green-500
                                       focus:border-transparent transition-all duration-300 pl-11"
                                :placeholder="t('bookingForm.personal.lastName.placeholder')"
                            />
                            <div class="absolute left-4 top-1/2 -translate-y-1/2">
                                <i class="fas fa-user text-gray-400 group-focus-within:text-green-500 transition-colors"></i>
                            </div>
                        </div>
                        <p v-if="getErrorMessage('lastName')" class="mt-1 text-sm text-red-500">
                            {{ getErrorMessage('lastName') }}
                        </p>
                    </div>

                    <!-- Phone Number -->
                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700 flex items-center gap-2">
                            {{ t('bookingForm.personal.phone.label') }}
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="mt-1 relative justify-start">
                            <PhoneInput
                                v-model="form.contactNumber"
                                :error="getErrorMessage('contactNumber')"
                                class="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-green-500
                                           focus:border-transparent transition-all duration-300"
                            />
                        </div>
                    </div>

                    <!-- Contact Information -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <CountrySearch
                                v-model="form.locationCountry"
                                :error="getErrorMessage('locationCountry')"
                                required
                                class="transform transition-all duration-300 hover:scale-[1.01]"
                            />

                        <!-- Email -->
                        <div class="relative group">
                            <label class="text-sm font-medium text-gray-700 flex items-center gap-2">
                                Email Address
                                <span class="text-red-500">*</span>
                            </label>
                            <div class="mt-1 relative">
                                <input
                                    v-model="form.email"
                                    type="email"
                                    required
                                    class="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-green-500
                                           focus:border-transparent transition-all duration-300 pl-11"
                                    placeholder="your.email@example.com"
                                />
                                <div class="absolute left-4 top-1/2 -translate-y-1/2">
                                    <i class="fas fa-envelope text-gray-400 group-focus-within:text-green-500 transition-colors"></i>
                                </div>
                            </div>
                            <p v-if="getErrorMessage('email')" class="mt-1 text-sm text-red-500">
                                {{ getErrorMessage('email') }}
                            </p>
                        </div>
                    </div>

                    <!-- Location Country -->

                    <!-- User Type Selection -->
                    <div class="space-y-3">
                        <label class="text-sm font-medium text-gray-700">User Type</label>
                        <div class="grid grid-cols-2 gap-4">
                            <label
                                v-for="type in ['Care Seeker', 'Carer']"
                                :key="type"
                                class="relative cursor-pointer"
                            >
                                <input
                                    type="radio"
                                    v-model="form.userType"
                                    :value="type"
                                    :name="'userType'"
                                    class="peer sr-only"
                                />
                                <div class="p-4 rounded-xl border-2 border-gray-200 bg-white hover:border-green-200
                                            transition-all duration-300 peer-checked:border-green-500 peer-checked:bg-green-50
                                            flex items-center justify-center gap-3">
                                    <i :class="`fas fa-${type === 'Care Seeker' ? 'user' : 'hand-holding-heart'}
                                              text-gray-400 group-hover:text-green-500 peer-checked:text-green-500`"></i>
                                    <span class="font-medium text-gray-600 peer-checked:text-green-700">{{ type }}</span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <!-- Travel Details Section -->
                <div v-show="currentStep === 1" class="space-y-6">
                    <!-- Main Flight Section -->
                    <div class="space-y-4">
                        <h3 class="text-lg font-medium text-gray-900">Main Flight Details</h3>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label class="block text-sm font-medium text-gray-700">Origin Airport</label>
                                <AirportSearch
                                    v-model="form.mainFlight.origin"
                                    placeholder="Search origin airport"
                                    class="mt-1"
                                />
                            </div>
                            <div>
                                <label class="block text-sm font-medium text-gray-700">Destination Airport</label>
                                <AirportSearch
                                    v-model="form.mainFlight.destination"
                                    placeholder="Search destination airport"
                                    class="mt-1"
                                />
                            </div>
                            <div>
                                <label class="block text-sm font-medium text-gray-700">Travel Date</label>
                                <input
                                    type="date"
                                    v-model="form.mainFlight.travelDate"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                    :min="new Date().toISOString().split('T')[0]"
                                />
                            </div>
                            <div>
                                <label class="block text-sm font-medium text-gray-700">Flight Number</label>
                                <input
                                    type="text"
                                    v-model="form.mainFlight.flightNumber"
                                    placeholder="e.g. BA123"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Layovers Section -->
                    <div class="space-y-4">
                        <div class="flex justify-between items-center">
                            <h3 class="text-lg font-medium text-gray-900">Layovers</h3>
                            <button
                                type="button"
                                @click="addLayover"
                                class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary rounded-md hover:bg-primary-dark"
                                aria-label="Add a layover to your journey"
                            >
                                <i class="fas fa-plus mr-2" aria-hidden="true"></i>
                                Add Layover
                            </button>
                        </div>

                        <div v-for="(layover, index) in form.layovers" :key="index" class="border rounded-lg p-4 space-y-4">
                            <div class="flex justify-between items-center">
                                <h4 class="font-medium">Layover {{ index + 1 }}</h4>
                                <button
                                    type="button"
                                    @click="removeLayover(index)"
                                    class="text-red-600 hover:text-red-800"
                                    aria-label="Remove this layover"
                                >
                                    <i class="fas fa-times" aria-hidden="true"></i>
                                </button>
                            </div>

                            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label class="block text-sm font-medium text-gray-700">Origin Airport</label>
                                    <AirportSearch
                                        v-model="layover.origin"
                                        placeholder="Search origin airport"
                                        class="mt-1"
                                    />
                                </div>
                                <div>
                                    <label class="block text-sm font-medium text-gray-700">Destination Airport</label>
                                    <AirportSearch
                                        v-model="layover.destination"
                                        placeholder="Search destination airport"
                                        class="mt-1"
                                    />
                                </div>
                                <div>
                                    <label class="block text-sm font-medium text-gray-700">Travel Date</label>
                                    <input
                                        type="date"
                                        v-model="layover.travelDate"
                                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                        :min="form.mainFlight.travelDate"
                                    />
                                </div>
                                <div>
                                    <label class="block text-sm font-medium text-gray-700">Flight Number</label>
                                    <input
                                        type="text"
                                        v-model="layover.flightNumber"
                                        :name="`layover_${index}_flightNumber`"
                                        placeholder="e.g. BA123"
                                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Preferences Section -->
                <div v-show="currentStep === 2" class="space-y-6">
                    <!-- Medical Information (Only for Care Seekers) -->
                    <div v-if="form.userType === 'Care Seeker'" class="space-y-4">
                        <h3 class="text-lg font-medium text-gray-900">Health Information</h3>
                        
                        <!-- Medical Conditions -->
                        <div class="relative group">
                            <label class="text-sm font-medium text-gray-700 flex items-center gap-2">
                                Medical Conditions
                                <span class="text-red-500">*</span>
                                <span class="text-gray-400 text-xs">(Required for Care Seekers)</span>
                            </label>
                            <div class="mt-1">
                                <textarea
                                    v-model="form.medical_conditions"
                                    rows="3"
                                    class="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-green-500
                                           focus:border-transparent transition-all duration-300"
                                    placeholder="Please describe any medical conditions that may affect your travel"
                                    required
                                ></textarea>
                            </div>
                        </div>

                        <!-- Special Requirements -->
                        <div class="relative group">
                            <label class="text-sm font-medium text-gray-700 flex items-center gap-2">
                                Special Requirements
                                <span class="text-gray-400 text-xs">(Optional)</span>
                            </label>
                            <div class="mt-1">
                                <textarea
                                    v-model="form.special_requirements"
                                    rows="3"
                                    class="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-green-500
                                           focus:border-transparent transition-all duration-300"
                                    placeholder="Please describe any special requirements or assistance needed during travel"
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- Existing Contact Method section -->
                    <div class="space-y-3">
                        <label class="text-sm font-medium text-gray-700">
                            {{ t('bookingForm.preferences.contactMethod.label') }}
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="grid grid-cols-2 gap-4">
                            <label
                                v-for="option in ['email', 'phone']"
                                :key="option"
                                class="relative cursor-pointer"
                            >
                                <input
                                    type="radio"
                                    v-model="form.preferredContactMode"
                                    :value="option"
                                    :name="'preferredContactMode'"
                                    required
                                    class="peer sr-only"
                                />
                                <div class="p-4 rounded-xl border-2 border-gray-200 bg-white hover:border-green-200
                                            transition-all duration-300 peer-checked:border-green-500 peer-checked:bg-green-50
                                            flex items-center justify-center gap-3">
                                    <i :class="`fas fa-${option === 'email' ? 'envelope' : 'phone'} text-gray-400
                                              group-hover:text-green-500 peer-checked:text-green-500`"></i>
                                    <span class="font-medium text-gray-600 peer-checked:text-green-700">
                                        {{ t(`bookingForm.preferences.contactMethod.${option}`) }}
                                    </span>
                                </div>
                            </label>
                        </div>
                    </div>

                    <!-- Preferred Call Time -->
                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700">
                            {{ t('bookingForm.preferences.callTime.label') }}
                        </label>
                        <div class="mt-1">
                            <TimeSelector v-model="form.preferredCallTime" />
                        </div>
                    </div>

                    <!-- Additional Details -->
                    <div class="relative group">
                        <label class="text-sm font-medium text-gray-700 flex items-center gap-2">
                            {{ t('bookingForm.preferences.additionalDetails.label') }}
                            <span class="text-gray-400 text-xs">{{ t('bookingForm.preferences.additionalDetails.optional') }}</span>
                        </label>
                        <div class="mt-1">
                            <textarea
                                v-model="form.enquiryDetails"
                                rows="4"
                                class="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-green-500
                                       focus:border-transparent transition-all duration-300"
                                :placeholder="t('bookingForm.preferences.additionalDetails.placeholder')"
                            ></textarea>
                            <p class="mt-2 text-sm text-gray-500 text-right">
                                {{ enquiryDetailsLength }} characters
                            </p>
                        </div>
                    </div>

                    <!-- Consent -->
                    <div class="space-y-3">
                        <label class="text-sm font-medium text-gray-700">
                            {{ t('bookingForm.preferences.consent.label') }}
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="grid grid-cols-2 gap-4">
                            <label
                                v-for="option in ['yes', 'no']"
                                :key="option"
                                class="relative cursor-pointer"
                            >
                                <input
                                    type="radio"
                                    v-model="form.consent"
                                    value="yes"
                                    name="consent"
                                    required
                                    class="peer sr-only"
                                />
                                <div class="p-4 rounded-xl border-2 border-gray-200 bg-white hover:border-green-200
                                            transition-all duration-300 peer-checked:border-green-500 peer-checked:bg-green-50
                                            flex items-center justify-center gap-3">
                                    <i :class="`fas fa-${option === 'yes' ? 'check' : 'times'} text-gray-400
                                              group-hover:text-green-500 peer-checked:text-green-500`"></i>
                                    <span class="font-medium text-gray-600 peer-checked:text-green-700">
                                        {{ t(`bookingForm.preferences.consent.${option}`) }}
                                    </span>
                                </div>
                            </label>
                        </div>
                        <p v-if="getErrorMessage('consent')" class="mt-2 text-sm text-red-600 font-medium">
                            {{ getErrorMessage('consent') }}
                        </p>
                    </div>

                    <!-- Add consent checkboxes before the final submit button -->
                    <div class="flex items-start">
                        <div class="flex items-center h-5">
                            <input
                                type="checkbox"
                                v-model="form.age_consent"
                                class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                            />
                        </div>
                        <div class="ml-3 text-sm">
                            <label class="font-medium text-gray-700">
                                {{ t('bookingForm.consent.age') }}
                            </label>
                        </div>
                    </div>

                    <div class="flex items-start">
                        <div class="flex items-center h-5">
                            <input
                                type="checkbox"
                                v-model="form.privacy_policy_consent"
                                class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                            />
                        </div>
                        <div class="ml-3 text-sm">
                            <label class="font-medium text-gray-700">
                                {{ t('bookingForm.consent.privacy') }}
                            </label>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label class="block text-gray-600 text-sm font-bold mb-2">{{ t('bookingForm.referralCode.label') }}</label>
                        <div class="relative">
                            <i class="fas fa-ticket absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"></i>
                            <input
                                v-model="form.referralCode"
                                type="text"
                                class="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:border-primary"
                                :placeholder="t('bookingForm.referralCode.placeholder')"
                            >
                        </div>
                        <p v-if="form.errors.referralCode" class="mt-1 text-sm text-red-600">
                            {{ form.errors.referralCode }}
                        </p>
                    </div>
                </div>

                <!-- Navigation Buttons -->
                <div class="flex justify-between pt-6 border-t border-gray-100">
                    <button
                        type="button"
                        v-if="currentStep > 0"
                        @click="previousStep"
                        class="flex items-center gap-2 px-6 py-3 rounded-xl text-gray-600 hover:text-gray-800
                               transition-colors duration-300"
                        aria-label="Go to previous step"
                    >
                        <i class="fas fa-arrow-left" aria-hidden="true"></i>
                        {{ t('bookingForm.navigation.previous') }}
                    </button>
                    <div class="ml-auto flex gap-4">
                        <button
                            v-if="currentStep < steps.length - 1"
                            type="button"
                            @click="nextStep"
                            :disabled="!isStepComplete[steps[currentStep].id]"
                            class="flex items-center gap-2 px-6 py-3 rounded-xl bg-[#034328] text-white
                                   hover:bg-[#024024] transition-colors duration-300 disabled:opacity-50
                                   disabled:cursor-not-allowed"
                            aria-label="Go to next step"
                        >
                            {{ t('bookingForm.navigation.next') }}
                            <i class="fas fa-arrow-right" aria-hidden="true"></i>
                        </button>
                        <button
                            v-else
                            type="submit"
                            :disabled="form.processing || !isStepComplete.preferences"
                            class="flex items-center gap-2 px-6 py-3 rounded-xl bg-green-500 text-white
                                   hover:bg-green-600 transition-colors duration-300 disabled:opacity-50
                                   disabled:cursor-not-allowed"
                        >
                            <span v-if="form.processing" class="flex items-center gap-2">
                                <i class="fas fa-circle-notch fa-spin"></i>
                                {{ t('bookingForm.navigation.processing') }}
                            </span>
                            <span v-else class="flex items-center gap-2">
                                {{ t('bookingForm.navigation.complete') }}
                                <i class="fas fa-check"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <!-- Success Modal -->
        <TransitionRoot appear :show="showSuccess" as="template">
            <Dialog as="div" @close="closeSuccess" class="relative z-50">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-black/30 backdrop-blur-sm"></div>
                </TransitionChild>

                <div class="fixed inset-0 overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild
                            as="template"
                            enter="duration-300 ease-out"
                            enter-from="opacity-0 scale-95"
                            enter-to="opacity-100 scale-100"
                            leave="duration-200 ease-in"
                            leave-from="opacity-100 scale-100"
                            leave-to="opacity-0 scale-95"
                        >
                            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div class="text-center">
                                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <i class="fas fa-check text-xl text-green-600"></i>
                                    </div>
                                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mt-4">
                                        {{ t('bookingForm.success.title') }}
                                    </DialogTitle>
                                    <div class="mt-2">
                                        <p class="text-sm text-gray-500">
                                            {{ t('bookingForm.success.message') }}
                                        </p>
                                    </div>
                                    <div class="mt-4">
                                        <button
                                            type="button"
                                            class="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                            @click="closeSuccess"
                                        >
                                            {{ t('bookingForm.success.button') }}
                                        </button>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- OTP Modal -->
        <TransitionRoot appear :show="showOtpModal" as="template">
            <Dialog as="div" @close="showOtpModal = false" class="relative z-50">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-black/30 backdrop-blur-sm"></div>
                </TransitionChild>

                <div class="fixed inset-0 overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4">
                        <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <DialogTitle class="text-lg font-medium text-gray-900 text-center">
                                Verify your {{ verificationMethod }}
                            </DialogTitle>

                            <div class="mt-4">
                                <div class="flex justify-center space-x-4 mb-6">
                                    <button
                                        @click="verificationMethod = 'email'"
                                        :class="['px-4 py-2 rounded transition-colors',
                                            verificationMethod === 'email'
                                                ? 'bg-[#034328] text-white'
                                                : 'bg-gray-100 text-gray-700']"
                                        aria-label="Use email verification"
                                        :aria-pressed="verificationMethod === 'email'"
                                    >
                                        Email
                                    </button>
                                    <button
                                        @click="verificationMethod = 'phone'"
                                        :class="['px-4 py-2 rounded transition-colors',
                                            verificationMethod === 'phone'
                                                ? 'bg-[#034328] text-white'
                                                : 'bg-gray-100 text-gray-700']"
                                        aria-label="Use phone verification"
                                        :aria-pressed="verificationMethod === 'phone'"
                                    >
                                        Phone
                                    </button>
                                </div>

                                <p class="text-sm text-gray-500 text-center mb-4">
                                    Enter the verification code sent to your {{ verificationMethod }}
                                </p>

                                <OtpInput
                                    v-model="otpCode"
                                    :length="6"
                                />

                                <p v-if="otpError" class="mt-2 text-sm text-red-600 text-center">
                                    {{ otpError }}
                                </p>

                                <div class="mt-6 flex justify-between items-center">
                                    <button
                                        type="button"
                                        @click="sendOtp"
                                        class="text-primary hover:text-primary-dark text-sm"
                                        aria-label="Resend verification code"
                                    >
                                        Resend Code
                                    </button>
                                    <button
                                        type="button"
                                        @click="verifyOtp"
                                        :disabled="isVerifying"
                                        class="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:opacity-50"
                                        aria-label="Verify your code"
                                    >
                                        {{ isVerifying ? 'Verifying...' : 'Verify' }}
                                    </button>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
    transform: translateX(0);
    opacity: 1;
}
</style>
