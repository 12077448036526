<!-- src/Components/TimeSelector.vue -->
<script setup>
const props = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    error: {
        type: String,
        default: ''
    }
});

const emit = defineEmits(['update:modelValue']);

const timeSlots = [
    '9:00 AM - 12:00 PM',
    '12:00 PM - 3:00 PM',
    '3:00 PM - 6:00 PM',
    '6:00 PM - 9:00 PM'
];
</script>

<template>
    <div class="space-y-3">
        <label class="text-sm font-medium text-gray-700">Preferred Call Time</label>
        <div class="grid grid-cols-2 gap-4">
            <label
                v-for="time in timeSlots"
                :key="time"
                class="relative cursor-pointer"
            >
                <input
                    type="radio"
                    :value="time"
                    :checked="modelValue === time"
                    @change="emit('update:modelValue', time)"
                    class="peer sr-only"
                />
                <div class="p-4 rounded-xl border-2 border-gray-200 bg-white hover:border-green-200
                            transition-all duration-300 peer-checked:border-green-500 peer-checked:bg-green-50
                            flex items-center justify-center gap-3">
                    <i class="fas fa-clock text-gray-400 peer-checked:text-green-500"></i>
                    <span class="font-medium text-gray-600 peer-checked:text-green-700">{{ time }}</span>
                </div>
            </label>
        </div>
        <p v-if="error" class="mt-1 text-sm text-red-500">{{ error }}</p>
    </div>
</template>
