<template>
    <picture>
        <source
            v-if="webpSrc"
            :type="'image/webp'"
            :srcset="generateSrcSet(webpSrc)"
            :sizes="sizes"
        />
        <img
            :src="src"
            :srcset="generateSrcSet(src)"
            :sizes="sizes"
            :alt="$t(alt)"
            :class="className"
            loading="lazy"
            decoding="async"
            :width="width"
            :height="height"
            @error="handleError"
            ref="imageRef"
        />
    </picture>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const imageRef = ref(null);

const props = defineProps({
    src: {
        type: String,
        required: true
    },
    webpSrc: {
        type: String,
        default: ''
    },
    alt: {
        type: String,
        required: true
    },
    className: {
        type: String,
        default: ''
    },
    width: {
        type: [Number, String],
        default: null
    },
    height: {
        type: [Number, String],
        default: null
    },
    sizes: {
        type: String,
        default: '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
    },
    fallbackSrc: {
        type: String,
        default: '/images/logo.png'
    }
});

const generateSrcSet = (src) => {
    if (!src) return '';
    
    const widths = [320, 640, 768, 1024, 1280, 1536];
    const baseUrl = src.split('?')[0];
    const extension = baseUrl.split('.').pop();
    
    return widths
        .map(width => {
            const resizedUrl = baseUrl.replace(`.${extension}`, `-${width}.${extension}`);
            return `${resizedUrl} ${width}w`;
        })
        .join(', ');
};

const handleError = (e) => {
    if (props.fallbackSrc && e.target.src !== props.fallbackSrc) {
        e.target.src = props.fallbackSrc;
    }
};

let observer;

onMounted(() => {
    if (imageRef.value) {
        observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const img = entry.target;
                        if (img.dataset.src) {
                            img.src = img.dataset.src;
                        }
                        observer.unobserve(img);
                    }
                });
            },
            {
                rootMargin: '50px'
            }
        );
        observer.observe(imageRef.value);
    }
});

onUnmounted(() => {
    if (observer) {
        observer.disconnect();
    }
});
</script> 