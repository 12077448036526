<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import countries from '../../../public/data/countries.json';
import { useLocation } from '../Composables/useLocation';

const props = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    error: {
        type: [String, Array],
        default: ''
    },
    label: {
        type: String,
        default: 'Country'
    },
    required: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue']);

const { countryCode, loading: locationLoading, error: locationError } = useLocation();
const selectedCountry = ref(null);
const showDropdown = ref(false);
const searchQuery = ref('');
const dropdownRef = ref(null);
const highlightedIndex = ref(-1);

// Set default country based on location
onMounted(async () => {
    if (countryCode.value) {
        const detectedCountry = countries.countries.find(
            country => country.code === countryCode.value
        );
        if (detectedCountry) {
            selectedCountry.value = detectedCountry;
            emit('update:modelValue', detectedCountry.name);
            searchQuery.value = detectedCountry.name;
        }
    }
});

// Debounced search
const debouncedSearch = ref('');
let debounceTimeout;

watch(searchQuery, (newValue) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
        debouncedSearch.value = newValue;
    }, 300);
});

const searchScore = (country, query) => {
    const searchLower = query.toLowerCase();
    const nameLower = country.name.toLowerCase();
    const codeLower = country.code.toLowerCase();
    const dialLower = country.dialCode.toLowerCase();

    if (nameLower === searchLower) return 100;
    if (codeLower === searchLower) return 90;
    if (dialLower === searchLower) return 80;
    if (nameLower.startsWith(searchLower)) return 70;
    if (codeLower.startsWith(searchLower)) return 60;
    if (dialLower.startsWith(searchLower)) return 50;
    if (nameLower.includes(searchLower)) return 40;
    if (codeLower.includes(searchLower)) return 30;
    if (dialLower.includes(searchLower)) return 20;

    return 0;
};

const filteredCountries = computed(() => {
    if (!showDropdown.value) return [];

    const query = debouncedSearch.value;
    if (!query) return countries.countries;

    return countries.countries
        .map(country => ({
            ...country,
            score: searchScore(country, query)
        }))
        .filter(country => country.score > 0)
        .sort((a, b) => b.score - a.score);
});

const highlightMatch = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    return text.replace(regex, '<span class="text-green-600 font-bold">$1</span>');
};

const selectCountry = (country) => {
    selectedCountry.value = country;
    searchQuery.value = country.name;
    emit('update:modelValue', country.name);
    showDropdown.value = false;
    highlightedIndex.value = -1;
};

onMounted(() => {
    const handleClickOutside = (event) => {
        if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
            showDropdown.value = false;
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    onUnmounted(() => {
        document.removeEventListener('mousedown', handleClickOutside);
    });
});

const handleKeydown = (event) => {
    if (!showDropdown.value || filteredCountries.value.length === 0) return;

    switch (event.key) {
        case 'ArrowDown':
            event.preventDefault();
            highlightedIndex.value = (highlightedIndex.value + 1) % filteredCountries.value.length;
            break;
        case 'ArrowUp':
            event.preventDefault();
            highlightedIndex.value = highlightedIndex.value <= 0 ?
                filteredCountries.value.length - 1 : highlightedIndex.value - 1;
            break;
        case 'Enter':
            event.preventDefault();
            if (highlightedIndex.value >= 0) {
                selectCountry(filteredCountries.value[highlightedIndex.value]);
            }
            break;
        case 'Escape':
            showDropdown.value = false;
            highlightedIndex.value = -1;
            break;
    }
};

const formattedError = computed(() => {
    if (!props.error) return '';
    return Array.isArray(props.error) ? props.error[0] : props.error;
});

watch(() => props.modelValue, (newValue) => {
    if (!newValue) {
        selectedCountry.value = null;
        searchQuery.value = '';
    }
});
</script>

<template>
    <div class="relative" :class="{ 'z-[90]': showDropdown }" ref="dropdownRef">
        <label class="text-sm font-medium text-gray-700 flex items-center gap-2">
            {{ label }}
            <span v-if="required" class="text-red-500">*</span>
        </label>

        <div class="mt-1 relative">
            <input
                v-model="searchQuery"
                @focus="showDropdown = true"
                @keydown="handleKeydown"
                type="text"
                :disabled="locationLoading"
                class="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-green-500
                       focus:border-transparent transition-all duration-300 pl-11 disabled:opacity-75 disabled:cursor-wait"
                :class="{ 'border-red-500': formattedError }"
                :placeholder="locationLoading ? 'Detecting location...' : 'Search country...'"
            />

            <div class="absolute left-4 top-1/2 -translate-y-1/2">
                <template v-if="locationLoading">
                    <i class="fas fa-circle-notch fa-spin text-gray-400"></i>
                </template>
                <template v-else>
                    <span v-if="selectedCountry" class="text-xl">{{ selectedCountry.flag }}</span>
                    <i v-else class="fas fa-globe text-gray-400"></i>
                </template>
            </div>
        </div>

        <div v-show="showDropdown"
             class="absolute z-[100] w-full mt-1 bg-white rounded-xl shadow-lg border border-gray-100 max-h-60 overflow-y-auto">
            <template v-if="filteredCountries.length">
                <div
                    v-for="(country, index) in filteredCountries"
                    :key="country.code"
                    @click="selectCountry(country)"
                    class="px-4 py-2 flex items-center gap-3 cursor-pointer transition-colors"
                    :class="{ 'bg-green-100': index === highlightedIndex, 'hover:bg-green-50': index !== highlightedIndex }">
                    <span class="text-xl">{{ country.flag }}</span>
                    <span v-html="highlightMatch(country.name, searchQuery)"></span>
                    <span class="text-gray-400 text-sm ml-auto">{{ country.code }}</span>
                </div>
            </template>
            <div v-else class="px-4 py-2 text-gray-500">
                No countries found
            </div>
        </div>
    </div>
</template>

<style scoped>
.overflow-y-auto {
    scrollbar-width: thin;
    scrollbar-color: #E5E7EB transparent;
}

.overflow-y-auto::-webkit-scrollbar {
    width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: #E5E7EB;
    border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background-color: #D1D5DB;
}
</style>
