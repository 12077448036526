import './bootstrap';
import '../css/app.css';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from './ziggy';
import { Modal, ModalLink } from '@inertiaui/modal-vue';
import { renderApp } from '@inertiaui/modal-vue';
import axios from 'axios';
import { createI18n } from 'vue-i18n';
import i18n from './i18n';

// Import global components
import ResponsiveImage from '@/Components/ResponsiveImage.vue';
import HeroBanner from '@/Components/HeroBanner.vue';
import Navigation from '@/Components/Navigation.vue';
import Footer from '@/Components/Footer.vue';
import CookieBanner from '@/Components/CookieBanner.vue';
import VideoModal from '@/Components/VideoModal.vue';
import InfoPanel from '@/Components/InfoPanel.vue';
import ContactForm from '@/Components/ContactForm.vue';
import BookingForm from '@/Components/BookingForm.vue';
import WelcomePopup from '@/Components/WelcomePopup.vue';
import LanguageSelector from '@/Components/LanguageSelector.vue';
import CurrencySelector from '@/Components/CurrencySelector.vue';

// Set default language and axios headers
const language = localStorage.getItem('language') || 'en';
axios.defaults.headers.common['X-Language'] = language;

// Add language header to all future axios requests
axios.interceptors.request.use(config => {
    const currentLang = localStorage.getItem('language') || 'en';
    config.headers['X-Language'] = currentLang;
    return config;
});

// Performance optimizations
const prefetchLinks = () => {
    const links = document.querySelectorAll('a');
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const link = entry.target.href;
                if (link && !prefetchedLinks.has(link)) {
                    const prefetchLink = document.createElement('link');
                    prefetchLink.rel = 'prefetch';
                    prefetchLink.href = link;
                    document.head.appendChild(prefetchLink);
                    prefetchedLinks.add(link);
                }
                observer.unobserve(entry.target);
            }
        });
    });
    links.forEach(link => observer.observe(link));
};

const prefetchedLinks = new Set();

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

// Lazy load dependencies
const loadDependencies = async (app) => {
    // Load Font Awesome
    const [{ library, dom }, { fas }] = await Promise.all([
        import('@fortawesome/fontawesome-svg-core'),
        import('@fortawesome/free-solid-svg-icons')
    ]);
    library.add(fas);
    dom.watch();

    // Load Toast
    const [{ default: Toast }, css] = await Promise.all([
        import('vue-toastification'),
        import('vue-toastification/dist/index.css')
    ]);
    app.use(Toast, {
        transition: "Vue-Toastification__bounce",
        maxToasts: 3,
        newestOnTop: true,
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
    });

    // Load GTM in production
    if (import.meta.env.PROD) {
        const { default: VueGtm } = await import('@gtm-support/vue-gtm');
        app.use(VueGtm, {
            id: 'GTM-KNCG6TXT',
            enabled: true,
            debug: false,
            loadScript: true,
        });

        // Load PostHog in production
        const { default: posthog } = await import('@/Plugins/posthog.js');
        app.use(posthog);
    }
};

const app = createInertiaApp({
    title: title => title ? `${title} - ${appName}` : `${appName}`,
    resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        // Use i18n
        app.use(i18n);

        // Register global components
        app.component('ResponsiveImage', ResponsiveImage);
        app.component('HeroBanner', HeroBanner);
        app.component('Navigation', Navigation);
        app.component('Footer', Footer);
        app.component('CookieBanner', CookieBanner);
        app.component('VideoModal', VideoModal);
        app.component('InfoPanel', InfoPanel);
        app.component('ContactForm', ContactForm);
        app.component('BookingForm', BookingForm);
        app.component('WelcomePopup', WelcomePopup);
        app.component('LanguageSelector', LanguageSelector);
        app.component('CurrencySelector', CurrencySelector);
        app.component('Modal', Modal);
        app.component('ModalLink', ModalLink);

        // Register global properties
        app.config.globalProperties.$language = {
            current: () => i18n.global.locale.value,
            set: (lang) => {
                localStorage.setItem('language', lang);
                i18n.global.locale.value = lang;
                axios.defaults.headers.common['X-Language'] = lang;
                document.documentElement.lang = lang;
            }
        };

        // Use plugins
        app.use(plugin);
        app.use(ZiggyVue);

        // Load dependencies
        loadDependencies(app);

        return app.mount(el);
    },
    progress: {
        color: '#2B8B6F',
        showSpinner: true,
    },
});

// Function to load Convai widget after consent
const loadConvaiWidget = () => {
    if (localStorage.getItem('cookie-consent') === 'accepted') {
        const script = document.createElement('script');
        script.src = 'https://elevenlabs.io/convai-widget/index.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }
};

// Check cookie consent and load widget
document.addEventListener('DOMContentLoaded', () => {
    loadConvaiWidget();
    prefetchLinks();
    
    // Add intersection observer for lazy loading
    const lazyImages = document.querySelectorAll('img[loading="lazy"]');
    const imageObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                img.src = img.dataset.src;
                imageObserver.unobserve(img);
            }
        });
    });
    lazyImages.forEach(img => imageObserver.observe(img));
});

// Register Service Worker
if ('serviceWorker' in navigator && import.meta.env.PROD) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
            .then(registration => {
                console.log('ServiceWorker registration successful');
            })
            .catch(error => {
                console.error('ServiceWorker registration failed:', error);
            });
    });
}
