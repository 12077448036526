<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import { Link } from '@inertiajs/vue3';

const { t } = useI18n();

// Convert offers to computed property to react to language changes
const offers = computed(() => [
    {
        userType: t('offers.carer.type'),
        icon: 'fa-heart',
        message: t('offers.carer.bonus'),
        color: 'text-emerald-400',
        route: 'carer'
    },
    {
        userType: t('offers.seeker.type'),
        icon: 'fa-hands-helping',
        message: t('offers.careSeeker.discount'),
        color: 'text-sky-400',
        route: 'care-seeker'
    }
]);

const currentOfferIndex = ref(0);
const showTicker = ref(true);
const page = usePage();

// Check if user is logged in
const isLoggedIn = computed(() => page.props.auth.user);

const handleScroll = () => {
    showTicker.value = window.scrollY === 0;
};

let interval;

onMounted(() => {
    if (!isLoggedIn.value) {
        // Only set up scroll listener and ticker if user is not logged in
        window.addEventListener('scroll', handleScroll);
        
        // Rotate offers every 5 seconds
        interval = setInterval(() => {
            currentOfferIndex.value = (currentOfferIndex.value + 1) % offers.value.length;
        }, 5000);
    }
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
    if (interval) {
        clearInterval(interval);
    }
});
</script>

<template>
    <Transition
        enter-active-class="transition-all duration-300 ease-out"
        enter-from-class="transform -translate-y-full"
        enter-to-class="transform translate-y-0"
        leave-active-class="transition-all duration-300 ease-in"
        leave-from-class="transform translate-y-0"
        leave-to-class="transform -translate-y-full"
    >
        <div 
            v-if="showTicker && !isLoggedIn" 
            class="bg-[#034328]/95 text-white w-full py-1.5 fixed top-0 z-50"
        >
            <div class="max-w-7xl mx-auto px-4">
                <div class="overflow-hidden relative h-6">
                    <transition
                        enter-active-class="transition duration-500 ease-out"
                        enter-from-class="transform translate-x-full opacity-0"
                        enter-to-class="transform translate-x-0 opacity-100"
                        leave-active-class="transition duration-500 ease-in"
                        leave-from-class="transform translate-x-0 opacity-100"
                        leave-to-class="transform -translate-x-full opacity-0"
                    >
                        <div 
                            :key="currentOfferIndex"
                            class="absolute inset-x-0 flex items-center justify-center lg:text-sm md:text-sm text-xs font-medium"
                        >
                            <div class="flex items-center animate-ticker whitespace-nowrap">
                                <i :class="['fas', offers[currentOfferIndex].icon, 'text-white', 'lg:mr-2 md:mr-2 mr-1 lg:text-base md:text-base text-sm']"></i>
                                <span class="font-semibold lg:mr-2 md:mr-2 mr-1 text-[#e6ecea]">{{ offers[currentOfferIndex].userType }}:</span>
                                <span class="text-[#b3c7b9] lg:inline md:inline hidden">{{ offers[currentOfferIndex].message }}</span>
                                <span class="text-[#b3c7b9] lg:hidden md:hidden inline">{{ offers[currentOfferIndex].message.substring(0, 25) }}...</span>
                                <Link 
                                    :href="route('booking', offers[currentOfferIndex].route)"
                                    class="lg:ml-3 md:ml-3 ml-2 bg-white text-[#034328] lg:px-3 md:px-3 px-2 py-0.5 rounded-full hover:bg-gray-100 transition-colors font-medium text-xs lg:text-sm md:text-sm"
                                >
                                    {{ t('offers.cta') }}
                                </Link>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.fixed {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

@keyframes ticker {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    10% {
        transform: translateX(0);
        opacity: 1;
    }
    90% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

.animate-ticker {
    animation: ticker 5s ease-in-out;
    width: max-content;
}

/* Ensure smooth transitions */
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
}
</style>