<template>
    <OfferTicker />
    <nav :class="[
        'sticky z-40 bg-white shadow-sm border-b border-gray-100 transition-all duration-300 ease-out',
        isTickerActuallyVisible ? 'top-8' : 'top-0'
    ]">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-16">
            <div class="flex justify-between h-16 items-center">
                <!-- Logo -->
                <div class="flex-shrink-0 flex items-center">
                    <Link :href="route('home')" class="flex items-center gap-2 transition hover:opacity-90">
                        <img :src="logo" alt="Buddy4Travel" class="h-10 rounded-md">
                    </Link>
                </div>

                <!-- Desktop Navigation -->
                <div class="hidden md:flex md:items-center md:space-x-8">
                    <Link
                        v-for="item in navItems"
                        :key="item.path"
                        :href="item.path"
                        class="inline-flex items-center px-1 pt-1 text-md font-medium text-gray-600 transition-colors duration-200 border-b-2 hover:text-[#034328] hover:border-[#034328]"
                        :class="{
                            'border-[#034328] border-b-2 text-[#034328] font-bold': isCurrentPath(item.path),
                            'border-transparent': !isCurrentPath(item.path)
                        }"
                    >
                        {{ item.label }}
                    </Link>
                </div>

                <!-- Desktop Right buttons -->
                <div class="hidden md:flex md:items-center md:space-x-3">
                    <LanguageSelector />

                    <template v-if="user">
                        <Link
                            :href="route('dashboard')"
                            class="px-4 py-2 border-2 text-sm border-[#034328] text-[#034328] rounded-full flex items-center justify-center hover:bg-[#034328] hover:text-white transition-colors"
                        >
                            {{ t('nav.dashboard') }}
                        </Link>
                        <button
                            @click="logout"
                            class="px-4 py-2 text-sm font-medium text-white bg-[#034328] rounded-full hover:bg-[#024024] transition-colors shadow-sm hover:shadow"
                        >
                            {{ t('nav.logout') }}
                        </button>
                    </template>
                    <template v-else>
                        <Link
                            :href="route('login')"
                            class="px-4 py-2 border-2 text-sm border-[#034328] text-[#034328] rounded-full flex items-center justify-center hover:bg-[#034328] hover:text-white transition-colors"
                            aria-label="Log in to your account"
                        >
                            {{ t('nav.login') }}
                        </Link>
                        <Link
                            :href="route('register')"
                            class="px-4 py-2 text-sm font-medium text-white bg-[#034328] rounded-full hover:bg-[#024024] transition-colors shadow-sm hover:shadow"
                            aria-label="Create a new account"
                        >
                        {{ t('common.joinNow') }}
                        </Link>
                    </template>
                </div>

                <!-- Mobile menu button -->
                <div class="flex md:hidden">
                    <button
                        @click="toggleMobileMenu"
                        class="relative inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-[#034328] hover:bg-gray-50 transition-colors focus:outline-none"
                        aria-expanded="false"
                        aria-label="Toggle navigation menu"
                    >
                        <span class="sr-only">{{ isMobileMenuOpen ? t('nav.close') : t('nav.open') }}</span>
                        <component
                            :is="isMobileMenuOpen ? icons.x : icons.menu"
                            class="w-6 h-6"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </div>
        </div>

        <!-- Mobile menu -->
        <transition
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="transform -translate-y-3 opacity-0"
            enter-to-class="transform translate-y-0 opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="transform translate-y-0 opacity-100"
            leave-to-class="transform -translate-y-3 opacity-0"
        >
            <div v-show="isMobileMenuOpen" class="md:hidden bg-white shadow-lg">
                <div class="max-w-7xl mx-auto px-4 pt-2 pb-4 space-y-1">
                    <Link
                        v-for="item in navItems"
                        :key="item.path"
                        :href="item.path"
                        class="block px-3 py-2.5 rounded-lg text-base font-medium text-gray-600 hover:text-[#034328] hover:bg-gray-50 transition-colors"
                        :class="{
                            'bg-gray-50 text-[#034328] font-semibold': isCurrentPath(item.path)
                        }"
                        @click="isMobileMenuOpen = false"
                    >
                        {{ item.label }}
                    </Link>

                    <div class="pt-4 mt-4 border-t border-gray-200">
                        <LanguageSelector />

                        <div class="flex items-center gap-3 mt-3 px-2">
                            <template v-if="user">
                                <Link
                                    :href="route('dashboard')"
                                    class="flex-1 py-2.5 text-center text-[#034328] border-2 border-[#034328] rounded-lg hover:bg-[#034328] hover:text-white transition-colors"
                                    @click="isMobileMenuOpen = false"
                                >
                                    {{ t('nav.dashboard') }}
                                </Link>
                                <button
                                    @click="logout"
                                    class="flex-1 py-2.5 text-center bg-[#034328] text-white rounded-lg hover:bg-[#024024] transition-colors shadow-sm"
                                >
                                    {{ t('nav.logout') }}
                                </button>
                            </template>
                            <template v-else>
                                <Link
                                    :href="route('login')"
                                    class="flex-1 py-2.5 text-center text-[#034328] border-2 border-[#034328] rounded-lg hover:bg-[#034328] hover:text-white transition-colors"
                                    @click="isMobileMenuOpen = false"
                                    aria-label="Log in to your account"
                                >
                                    {{ t('nav.login') }}
                                </Link>
                                <Link
                                    :href="route('register')"
                                    class="flex-1 py-2.5 text-center bg-[#034328] text-white rounded-lg hover:bg-[#024024] transition-colors shadow-sm"
                                    @click="isMobileMenuOpen = false"
                                    aria-label="Create a new account"
                                >
                                    {{ t('common.joinNow') }}
                                </Link>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </nav>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { Link, usePage, router } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import { loadNavigationIcons } from '@/Utils/dynamicImports'
import LanguageSelector from './LanguageSelector.vue'
import logo from '~/images/logo_full.jpeg'
import { route } from '@/ziggy'
import OfferTicker from './OfferTicker.vue'
// Define page props type
interface User {
    id: number
    name: string
    email: string
}

interface PageProps {
    auth: {
        user: User | null
    }
    [key: string]: any
}

// Initialize i18n
const { t } = useI18n()

// State
const isMobileMenuOpen = ref(false)
const icons = ref<{ menu?: any; x?: any }>({})
const isScrolledToTop = ref(true);

// Current route and auth state
const page = usePage<PageProps>()
const currentPath = computed(() => page.url)
const user = computed(() => page.props.auth?.user)

// Computed property to check if the ticker should be visible
const isTickerActuallyVisible = computed(() => {
    return !user.value && isScrolledToTop.value;
});

// Navigation items with translations
const navItems = computed(() => [
    { label: t('nav.home'), path: route('home') },
    { label: t('nav.about'), path: route('about') },
    { label: t('nav.vision'), path: route('vision') },
    { label: t('nav.services'), path: route('services')},
    { label: t('nav.flightTracker'), path: route('flight-tracker')},
    { label: t('nav.contact'), path: route('contact') },
])

// Toggle mobile menu
const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value
}

// Check if path is current
const isCurrentPath = (path: string) => {
    const menuItem = path.split('/').pop();
    const current = currentPath.value;

    if (path === route('home')) {
        return current === '/' || current === '';
    }

    const matchableRoutes = ['flight-tracker', 'service-packages', 'about', 'vision', 'contact'];

    if (menuItem && matchableRoutes.includes(menuItem)) {
        return current.includes(menuItem);
    }

    return false;
};


// Handle logout
const logout = () => {
    router.post(route('logout'))
}

// Scroll handling function
const handleNavScroll = () => {
    isScrolledToTop.value = window.scrollY === 0;
};

// Lifecycle hooks
onMounted(async () => {
    const loadedIcons = await loadNavigationIcons();
    icons.value = {
        menu: loadedIcons.menu,
        x: loadedIcons.x
    };
    window.addEventListener('scroll', handleNavScroll);
    handleNavScroll();
})

onUnmounted(() => {
    window.removeEventListener('scroll', handleNavScroll);
})
</script>

<style scoped>
/* Fade transition for dropdowns */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Active link transition */
.border-b-2 {
    transition: border-color 0.2s ease-in-out;
}
</style> 