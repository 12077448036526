<!-- Components/PhoneInput.vue -->
<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import countries from '../../../public/data/countries.json';
import { useLocation } from '../Composables/useLocation';

const props = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    error: {
        type: [String, Array],
        default: ''
    }
});

const emit = defineEmits(['update:modelValue']);

// Initialize location detection
const { countryCode, loading: locationLoading, error: locationError } = useLocation();

const selectedCountry = ref({
    name: "United States",
    code: "US",
    dialCode: "+1",
    flag: "🇺🇸"
});
const showDropdown = ref(false);
const searchQuery = ref('');
const dropdownRef = ref(null);
const inputRef = ref(null);

// Watch for country code changes from location detection
watch(countryCode, (newCode) => {
    if (newCode) {
        const detectedCountry = countries.countries.find(
            country => country.code === newCode
        );
        if (detectedCountry) {
            selectedCountry.value = detectedCountry;
            // Update phone number with new country code
            emit('update:modelValue', detectedCountry.dialCode + phoneNumberWithoutCode.value);
        }
    }
});

const filteredCountries = computed(() => {
    if (!searchQuery.value) return countries.countries;
    return countries.countries.filter(country =>
        country.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        country.dialCode.includes(searchQuery.value)
    );
});

const phoneNumberWithoutCode = computed(() => {
    return props.modelValue.replace(selectedCountry.value.dialCode, '');
});

const handleCountrySelect = (country) => {
    selectedCountry.value = country;
    showDropdown.value = false;
    searchQuery.value = '';
    emit('update:modelValue', country.dialCode + phoneNumberWithoutCode.value);
    inputRef.value?.focus();
};

const handlePhoneInput = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    emit('update:modelValue', selectedCountry.value.dialCode + newValue);
};

// Close dropdown when clicking outside
onMounted(() => {
    const handleClickOutside = (event) => {
        if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
            showDropdown.value = false;
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    onUnmounted(() => {
        document.removeEventListener('mousedown', handleClickOutside);
    });
});

const formattedError = computed(() => {
    if (!props.error) return '';
    if (Array.isArray(props.error)) return props.error[0];
    return props.error;
});
</script>

<template>
    <div class="relative group">
        <div class="relative flex gap-2">
            <!-- Country Selector -->
            <div class="relative lg:w-[100px] md:w-[90px] w-[80px]" ref="dropdownRef">
                <button
                    type="button"
                    @click="showDropdown = !showDropdown"
                    class="w-full h-full lg:px-3 md:px-2 px-2 py-3 flex items-center gap-1 bg-white rounded-xl border border-gray-200
                           hover:border-[#2B8B6F]/20 transition-all duration-300 focus:outline-none focus:ring-2
                           focus:ring-[#2B8B6F] focus:border-transparent"
                >
                    <template v-if="locationLoading">
                        <span class="lg:text-lg md:text-base text-base">{{ selectedCountry.flag }}</span>
                        <span class="lg:text-sm md:text-xs text-xs font-medium text-gray-600 truncate">{{ selectedCountry.dialCode }}</span>
                        <i class="fas fa-circle-notch fa-spin text-gray-400 ml-auto text-xs"></i>
                    </template>
                    <template v-else>
                        <span class="lg:text-lg md:text-base text-base">{{ selectedCountry.flag }}</span>
                        <span class="lg:text-sm md:text-xs text-xs font-medium text-gray-600 truncate">{{ selectedCountry.dialCode }}</span>
                        <i class="fas fa-chevron-down text-gray-400 text-xs ml-auto transition-transform duration-300"
                           :class="{ 'rotate-180': showDropdown }"></i>
                    </template>
                </button>

                <!-- Country Dropdown -->
                <div
                    v-if="showDropdown"
                    class="absolute z-50 left-0 lg:w-[280px] md:w-[250px] w-[220px] mt-2 bg-white rounded-xl shadow-lg border border-gray-100
                           overflow-hidden transition-all duration-300"
                >
                    <!-- Search Input -->
                    <div class="p-2 border-b border-gray-100">
                        <div class="relative">
                            <input
                                type="text"
                                v-model="searchQuery"
                                placeholder="Search countries..."
                                class="w-full pl-8 pr-3 py-2 text-xs lg:text-sm border border-gray-200 rounded-lg focus:outline-none
                                       focus:ring-2 focus:ring-[#2B8B6F] focus:border-transparent"
                            />
                            <i class="fas fa-search absolute left-2.5 top-1/2 -translate-y-1/2 text-gray-400 text-xs"></i>
                        </div>
                    </div>

                    <!-- Countries List -->
                    <div class="max-h-[200px] overflow-y-auto">
                        <button
                            v-for="country in filteredCountries"
                            :key="country.code"
                            type="button"
                            @click="handleCountrySelect(country)"
                            class="w-full px-3 py-2 text-left hover:bg-[#2B8B6F]/5 flex items-center gap-2
                                   transition-colors duration-300"
                        >
                            <span class="lg:text-base text-sm">{{ country.flag }}</span>
                            <div class="flex-1 min-w-0">
                                <p class="text-xs lg:text-sm font-medium text-gray-700 truncate">{{ country.name }}</p>
                                <p class="text-xs text-gray-500">{{ country.dialCode }}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Phone Input Field -->
            <div class="relative flex-1">
                <input
                    ref="inputRef"
                    type="tel"
                    :value="phoneNumberWithoutCode"
                    @input="handlePhoneInput"
                    placeholder="Enter phone number"
                    required
                    class="w-full lg:px-4 md:px-3 px-3 py-3 bg-white rounded-xl border border-gray-200 focus:outline-none
                           focus:ring-2 focus:ring-[#2B8B6F] focus:border-transparent transition-all duration-300
                           placeholder-gray-400 lg:text-base md:text-sm text-sm"
                    :class="{ 'border-red-500': formattedError }"
                />
            </div>
        </div>

        <!-- Error Messages -->
        <div class="mt-1.5 space-y-1">
            <p v-if="locationError" class="text-xs lg:text-sm text-amber-500 flex items-center gap-1">
                <i class="fas fa-exclamation-triangle"></i>
                {{ locationError }}
            </p>
            <p v-if="formattedError" class="text-xs lg:text-sm text-red-500 flex items-center gap-1">
                <i class="fas fa-exclamation-circle"></i>
                {{ formattedError }}
            </p>
        </div>
    </div>
</template>

<style scoped>
/* Custom scrollbar for the dropdown */
.overflow-y-auto {
    scrollbar-width: thin;
    scrollbar-color: rgba(43, 139, 111, 0.2) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
    width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
    background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: rgba(43, 139, 111, 0.2);
    border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background-color: rgba(43, 139, 111, 0.3);
}
</style>
