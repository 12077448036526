<script setup>
import { ref, onMounted } from 'vue';
import { Link } from '@inertiajs/vue3';

const showBanner = ref(false);

onMounted(() => {
    // Check if user has already accepted cookies
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
        showBanner.value = true;
    }
});

const acceptCookies = () => {
    localStorage.setItem('cookie-consent', 'accepted');
    // Dispatch event for third-party scripts
    window.dispatchEvent(new CustomEvent('cookie-consent-changed', { 
        detail: 'accepted'
    }));
    showBanner.value = false;
};

const declineCookies = () => {
    localStorage.setItem('cookie-consent', 'declined');
    // Dispatch event for third-party scripts
    window.dispatchEvent(new CustomEvent('cookie-consent-changed', { 
        detail: 'declined'
    }));
    showBanner.value = false;
};
</script>

<template>
    <Transition
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform -translate-x-full opacity-0"
        enter-to-class="transform translate-x-0 opacity-100"
        leave-active-class="transition duration-300 ease-in"
        leave-from-class="transform translate-x-0 opacity-100"
        leave-to-class="transform -translate-x-full opacity-0"
    >
        <div 
            v-if="showBanner" 
            class="fixed left-8 bottom-8 max-w-sm bg-white rounded-xl shadow-2xl z-50 overflow-hidden"
        >
            <div class="p-6">
                <div class="flex justify-between items-start mb-4">
                    <h3 class="text-lg font-semibold text-gray-900">Cookie Settings</h3>
                    <button 
                        @click="declineCookies"
                        class="text-gray-400 hover:text-gray-500"
                        aria-label="Close cookie banner"
                    >
                        <span class="sr-only">Close</span>
                        <i class="fas fa-times" aria-hidden="true"></i>
                    </button>
                </div>
                
                <p class="text-sm text-gray-600 mb-4">
                    We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
                </p>

                <div class="text-xs text-gray-500 mb-4">
                    <Link :href="route('legal', { policyName: 'privacy-policy' })" class="underline hover:text-[#034328]">
                        Privacy Policy
                    </Link>
                </div>

                <div class="flex gap-3">
                    <button
                        @click="acceptCookies"
                        class="flex-1 px-4 py-2.5 text-sm font-medium bg-[#034328] text-white rounded-lg hover:bg-[#024024] transition-colors"
                        aria-label="Accept all cookies"
                    >
                        Accept All
                    </button>
                    <button
                        @click="declineCookies"
                        class="flex-1 px-4 py-2.5 text-sm font-medium border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                        aria-label="Decline cookies"
                    >
                        Decline
                    </button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.shadow-2xl {
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
</style> 