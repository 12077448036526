// Lazy load Leaflet map
export const loadLeaflet = async () => {
    const [{ default: L }, css] = await Promise.all([
        import('leaflet'),
        import('leaflet/dist/leaflet.css')
    ]);
    return L;
};

// Lazy load Chart.js
export const loadChart = async () => {
    const { Chart, registerables } = await import('chart.js');
    Chart.register(...registerables);
    return Chart;
};

// Lazy load Font Awesome
export const loadFontAwesome = async () => {
    const [{ library, dom }, { fas }] = await Promise.all([
        import('@fortawesome/fontawesome-svg-core'),
        import('@fortawesome/free-solid-svg-icons')
    ]);

    // Configure Font Awesome
    library.add(fas);
    dom.watch();

    return fas;
};

// Lazy load Vue Datepicker
export const loadDatepicker = async () => {
    const [{ default: Datepicker }, css] = await Promise.all([
        import('@vuepic/vue-datepicker'),
        import('@vuepic/vue-datepicker/dist/main.css')
    ]);
    return Datepicker;
};

// Lazy load JSZip
export const loadJSZip = async () => {
    const { default: JSZip } = await import('jszip');
    return JSZip;
};

// Icon sets for different purposes
const iconSets = {
    social: {
        linkedin: () => import('lucide-vue-next/dist/esm/icons/linkedin'),
        youtube: () => import('lucide-vue-next/dist/esm/icons/youtube'),
        facebook: () => import('lucide-vue-next/dist/esm/icons/facebook'),
        instagram: () => import('lucide-vue-next/dist/esm/icons/instagram'),
        twitter: () => import('lucide-vue-next/dist/esm/icons/twitter')
    },
    navigation: {
        menu: () => import('lucide-vue-next/dist/esm/icons/menu'),
        x: () => import('lucide-vue-next/dist/esm/icons/x')
    },
    language: {
        globe: () => import('lucide-vue-next/dist/esm/icons/globe'),
        chevronDown: () => import('lucide-vue-next/dist/esm/icons/chevron-down'),
        search: () => import('lucide-vue-next/dist/esm/icons/search'),
        check: () => import('lucide-vue-next/dist/esm/icons/check'),
        x: () => import('lucide-vue-next/dist/esm/icons/x')
    }
};

// Cache for loaded icons
const iconCache = new Map();

// Load a single icon
export const loadIcon = async (iconName) => {
    if (iconCache.has(iconName)) {
        return iconCache.get(iconName);
    }

    // Find the icon in our sets
    for (const [setName, icons] of Object.entries(iconSets)) {
        if (iconName in icons) {
            const icon = await icons[iconName]();
            iconCache.set(iconName, icon.default);
            return icon.default;
        }
    }

    console.warn(`Icon ${iconName} not found in any icon set`);
    return null;
};

// Load a set of icons
const loadIconSet = async (setName) => {
    const set = iconSets[setName];
    if (!set) {
        console.warn(`Icon set ${setName} not found`);
        return {};
    }

    const loadedIcons = {};
    await Promise.all(
        Object.entries(set).map(async ([name, loader]) => {
            if (!iconCache.has(name)) {
                const icon = await loader();
                iconCache.set(name, icon.default);
            }
            loadedIcons[name] = iconCache.get(name);
        })
    );

    return loadedIcons;
};

// Specific icon set loaders
export const loadSocialIcons = () => loadIconSet('social');
export const loadNavigationIcons = () => loadIconSet('navigation');
export const loadLanguageIcons = () => loadIconSet('language'); 