# AirportSearch.vue
<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import debounce from 'lodash/debounce';

const props = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: true
    },
    error: {
        type: String,
        default: ''
    },
    zIndex: {
        type: Number,
        default: 10
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'dropdown-opened']);

const searchQuery = ref('');
const airports = ref([]);
const isLoading = ref(false);
const showDropdown = ref(false);
const searchError = ref(null);
const currentZIndex = ref(props.zIndex);
const selectedAirport = ref(null);

const fetchAirportDetails = async (code) => {
    if (!code) return;
    
    isLoading.value = true;
    searchError.value = null;

    try {
        const response = await fetch(route('amadeus.airport.search', {
            query: code,
            subType: 'AIRPORT'
        }));

        if (!response.ok) {
            throw new Error('Failed to fetch airport details');
        }

        const result = await response.json();
        if (result.success && result.data.length > 0) {
            const airport = result.data[0];
            selectedAirport.value = {
                code: airport.iataCode,
                name: `${airport.name} (${airport.address.cityName}, ${airport.address.countryName})`,
                details: {
                    id: airport.id,
                    type: airport.type,
                    subType: airport.subType,
                    city: airport.address.cityName,
                    country: airport.address.countryName,
                    geoCode: airport.geoCode
                }
            };
        }
    } catch (err) {
        console.error('Error fetching airport details:', err);
        searchError.value = 'Failed to fetch airport details. Please try again.';
    } finally {
        isLoading.value = false;
    }
};

watch(() => props.modelValue, (newValue) => {
    if (newValue && (!selectedAirport.value || selectedAirport.value.code !== newValue)) {
        fetchAirportDetails(newValue);
    }
}, { immediate: true });

const handleClickOutside = (event) => {
    if (!event.target.closest('.airport-search')) {
        showDropdown.value = false;
        currentZIndex.value = props.zIndex;
    }
};

const searchAirports = async (query) => {
    if (query.length < 2 || props.disabled) {
        airports.value = [];
        return;
    }

    isLoading.value = true;
    searchError.value = null;

    try {
        const response = await fetch(route('amadeus.airport.search', {
            query,
            subType: 'AIRPORT'
        }));

        if (!response.ok) {
            throw new Error('Failed to fetch airports');
        }

        const result = await response.json();
        if (result.success) {
            airports.value = result.data.map(airport => ({
                code: airport.iataCode,
                name: `${airport.name} (${airport.address.cityName}, ${airport.address.countryName})`,
                details: {
                    id: airport.id,
                    type: airport.type,
                    subType: airport.subType,
                    city: airport.address.cityName,
                    country: airport.address.countryName,
                    geoCode: airport.geoCode
                }
            }));
        } else {
            throw new Error(result.message || 'Failed to fetch airports');
        }
    } catch (err) {
        console.error('Error fetching airports:', err);
        searchError.value = 'Failed to fetch airports. Please try again.';
        airports.value = [];
    } finally {
        isLoading.value = false;
    }
};

const debouncedSearch = debounce((query) => {
    searchAirports(query);
}, 300);

const handleFocus = () => {
    if (!props.disabled) {
        showDropdown.value = true;
        currentZIndex.value = props.zIndex + 1;
        emit('dropdown-opened');
    }
};

const handleBlur = () => {
    if (!showDropdown.value) {
        currentZIndex.value = props.zIndex;
    }
};

const handleSelect = (airport) => {
    if (!props.disabled) {
        emit('update:modelValue', `${airport.code}`);
        showDropdown.value = false;
        searchQuery.value = '';
        currentZIndex.value = props.zIndex;
    }
};

watch(searchQuery, (newQuery) => {
    if (!props.disabled && newQuery) {
        debouncedSearch(newQuery);
    }
});

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
    <div
        class="relative airport-search"
        :style="{ zIndex: currentZIndex }"
    >
        <div
            class="relative"
            :class="{ 'opacity-75': disabled }"
        >
            <input
                type="text"
                v-model="searchQuery"
                @focus="handleFocus"
                @blur="handleBlur"
                :placeholder="disabled ? '' : 'Search airports...'"
                :disabled="disabled"
                class="mt-2 peer w-full h-12 bg-white border-2 border-gray-200 rounded-xl text-gray-900 focus:outline-none focus:border-emerald-500 font-medium text-lg px-4 transition-all duration-200 disabled:bg-gray-50 disabled:cursor-not-allowed disabled:border-gray-200"
            />

            <input type="hidden" :value="modelValue" />

            <i class="fas fa-search absolute right-4 top-6 text-gray-400"></i>

            <label class="absolute left-0 -top-6 text-sm font-semibold text-emerald-600">
                {{ label }} <span v-if="error" class="text-red-500">*</span>
            </label>

            <div v-if="modelValue && (selectedAirport || airports.find(a => a.code === modelValue))" class="mt-2 text-md text-gray-600">
                <div class="flex items-center space-x-2">
                    <i class="fas fa-plane-departure text-emerald-500"></i>
                    <span class="font-medium">
                        {{ (selectedAirport || airports.find(a => a.code === modelValue))?.details?.city }} ({{ modelValue }})
                    </span>
                </div>
            </div>
        </div>

        <div
            v-if="!disabled && showDropdown && (searchQuery.length >= 2 || airports.length > 0)"
            class="absolute left-0 right-0 mt-2 bg-white rounded-xl shadow-lg max-h-60 overflow-y-auto border border-gray-100 transition-all duration-200"
        >
            <div v-if="isLoading" class="p-4 text-center text-gray-500">
                <div class="animate-spin inline-block w-6 h-6 border-2 border-current border-t-transparent text-emerald-500 rounded-full mr-2"></div>
                <span class="text-sm font-medium">Searching airports...</span>
            </div>

            <div v-else-if="searchError" class="p-4 text-center text-red-500">
                <i class="fas fa-exclamation-circle mr-2"></i>
                <span class="text-sm font-medium">{{ searchError }}</span>
            </div>

            <ul v-else-if="airports.length > 0" class="py-2">
                <li
                    v-for="airport in airports"
                    :key="airport.code"
                    class="px-4 py-3 hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                    @click="handleSelect(airport)"
                >
                    <div class="flex items-center space-x-3">
                        <i class="fas fa-plane text-emerald-500"></i>
                        <div>
                            <div class="font-medium text-gray-900">
                                {{ airport.code }} - {{ airport.details.city }}
                            </div>
                            <div class="text-sm text-gray-500">
                                {{ airport.name }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div v-else-if="searchQuery.length >= 2" class="p-4 text-center text-gray-500">
                <i class="fas fa-search mr-2"></i>
                <span class="text-sm font-medium">No airports found</span>
            </div>

            <div v-else class="p-4 text-center text-gray-500">
                <i class="fas fa-keyboard mr-2"></i>
                <span class="text-sm font-medium">Type at least 2 characters to search</span>
            </div>
        </div>

        <div v-if="error" class="mt-1 text-sm text-red-500 flex items-center">
            <i class="fas fa-exclamation-circle mr-1"></i>
            {{ error }}
        </div>
    </div>
</template>
