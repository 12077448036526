import { route as ziggyRoute } from 'ziggy-js';

function route(name, params, absolute, config = null) {
    // In SSR context
    if (typeof window === 'undefined') {
        try {
            return ziggyRoute(name, params, absolute, config);
        } catch (e) {
            console.error('Ziggy route error (SSR):', e);
            return name;
        }
    }

    // In client context
    try {
        const routeFunction = window.route || ziggyRoute;
        return routeFunction(name, params, absolute, config || window.Ziggy);
    } catch (e) {
        console.error('Ziggy route error (client):', e);
        return name;
    }
}

export function ZiggyVue(app, options) {
    app.mixin({
        methods: {
            route: (name, params, absolute) => route(name, params, absolute, options)
        }
    });
}

export { route }; 